import React from 'react'
type StepperType = {
  name: string,
  desc: string
  containerClass: string
  nameClass: string
  lineClass: string
  descClass: string
}
function index({ name, desc, containerClass, nameClass, lineClass, descClass }: StepperType) {

  return (
    <div data-aos="fade-up" className={containerClass}>
      <div className={nameClass}>
        {name}
      </div>
      <div className={lineClass}></div>
      <div className={descClass}>
        {desc}
      </div>
    </div>
  )
}

export default index