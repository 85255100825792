import { useEffect, useRef, useState } from 'react';
import styles from './index.module.css';
import gsap from 'gsap';
import useAttractHoverEffect from '../../hooks/useAttractHoverEffect';
import { navLinks } from '../../utils/constants';
import { NavLink, useLocation } from 'react-router-dom';
import AnimatedButton from '../AnimatedButton';
import { resumeLink } from '../../utils/constants';
import { useMedia } from 'react-use';
import ReactGA from "react-ga4";

function Modal({ closeModal }: any) {
  const param = useLocation();
  const { pathname } = param;
  useAttractHoverEffect('#navLink0', 0.3, 0.3);
  useAttractHoverEffect('#navLink1', 0.3, 0.3);
  useAttractHoverEffect('#navLink2', 0.3, 0.3);
  useAttractHoverEffect('#navLink3', 0.3, 0.3);
  return (
    <section className="side_menu_option_container" id='side_menu_option_container'>
      <div className={styles.modal_container}>
        <div className={styles.modal_contaier_data}>
          <div className={styles.conatiner_navigation}>NAVIGATION</div>
          <div className={styles.side_bar_border}></div>
          <div className={styles.container_side_name}>
            {navLinks.map((link, idx) => {
              return (
                <NavLink key={link.to} to={link.to} onClick={() => closeModal(link.name)} className={styles.margin_top}>
                  <div className={styles.name_item} id={'navLink' + idx}>
                    <div className={pathname === link.to ? styles.active : styles.inactive}></div>
                    <div className={styles.mr_left}>{link.name}</div>
                  </div>
                </NavLink>
              )
            })}
            <a
              href={resumeLink}
              target='_blank'
              key={'/resume'} onClick={() => closeModal("Resume")} className={styles.margin_top} rel="noreferrer">
              <div className={styles.name_item} id={'navLink' + 3}>
                <div className={pathname === '/resume' ? styles.active : styles.inactive}></div>
                <div className={styles.mr_left}>Resume</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

function SideHeader({ scrollPositionValue }: any) {
  const [openModal, setModal] = useState(false);
  const isMobileSize = useMedia("(max-width: 480px)");
  var tl = useRef<any>(null);
  useEffect(() => {
    var upper = document.getElementsByClassName('upper');
    var lower = document.getElementsByClassName('lower');
    tl.current = gsap.timeline();
    tl.current.pause();
    tl.current.reverse();
    tl.current.to(upper, 0.5, { attr: { d: "M8,2 L2,8" }, x: 1, ease: 'Power2.easeInOut' }, 'start')
    tl.current.to(lower, 0.5, { attr: { d: "M8,8 L2,2" }, x: 1, ease: 'Power2.easeInOut' }, 'start');
  }, []);

  // Function to open the side navigation bar
  function openNav() {
    gsap.fromTo('#navLink0', { x: "100%", opacity: 0 }, { x: "0%", opacity: 1, duration: .8, ease: 'power2.out' });
    gsap.fromTo('#navLink1', { x: "100%", opacity: 0 }, { x: "0%", opacity: 1, duration: .9, ease: 'power2.out' });
    gsap.fromTo('#navLink2', { x: "100%", opacity: 0 }, { x: "0%", opacity: 1, duration: 1, ease: 'power2.out' });
    gsap.fromTo('#navLink3', { x: "100%", opacity: 0 }, { x: "0%", opacity: 1, duration: 1.1, ease: 'power2.out' });

    gsap.to("#sidebar", { borderTopLeftRadius: "0%", borderBottomLeftRadius: "0%", duration: 0.3, ease: "power2.in" });
    gsap.to("#sidebar", { x: -560, duration: 0.5, ease: "power2.out" });
  }

  // Function to close the side navigation bar
  function closeNav() {
    gsap.to("#sidebar", { x: 0, duration: 0.5, ease: "power2.in" });
    gsap.to("#sidebar", { borderTopLeftRadius: "50%", borderBottomLeftRadius: "50%", duration: 0.6, ease: "power2.in" });

    gsap.fromTo('#navLink0', { x: "0%", opacity: 1 }, { x: "100%", opacity: 0, duration: .64, ease: 'power2.in' });
    gsap.fromTo('#navLink1', { x: "0%", opacity: 1 }, { x: "100%", opacity: 0, duration: .65, ease: 'power2.in' });
    gsap.fromTo('#navLink2', { x: "0%", opacity: 1 }, { x: "100%", opacity: 0, duration: .66, ease: 'power2.in' });
    gsap.fromTo('#navLink3', { x: "0%", opacity: 1 }, { x: "100%", opacity: 0, duration: .67, ease: 'power2.in' });
  }

  function hideShowSideMenu() {
    setModal(!openModal);
    if (tl && tl.current) {
      tl.current.reversed() ? tl.current.play() : tl.current.reverse();
    }
    if (openModal) {
      closeNav()
    } else {
      openNav();
    }
  }
  if (isMobileSize) {
    return "";
  }
  return (
    <>
      <section className={styles.side_header_container}>
        <AnimatedButton openModal={openModal} id="menu-button" onClick={hideShowSideMenu} mouseEnterTop="130%" mouseEnterToScale={5} linkClassName={(scrollPositionValue === 0 && !openModal) ? 'menu_buttton_witout_effect' : openModal ? 'about_common_class menu_button_effect' : 'about_common_class menu_button_effect svg_margin'} circleClassName='about_common_circle_class menu_button_circle'>
          <svg viewBox="0 0 11 9" id='tumor'>
            <path d="M10,2 L2,2" className="upper" style={{ 'fill': 'none', 'stroke': '#FFF', strokeLinecap: 'round', display: 'blok' }} />
            <path d="M10,5 L2,5" className="lower" style={{ 'fill': 'none', 'stroke': '#FFF', strokeLinecap: 'round', display: 'blok' }} />
          </svg>
        </AnimatedButton>
      </section>
      <div className="sidebar" id="sidebar">
        <Modal closeModal={
          (link: string) => {
            hideShowSideMenu()
            ReactGA.event({
              category: "Side Header",
              action: `Click on ${link}`,
            });
          }
        } />
      </div>
    </>
  )
}

export default SideHeader