import React from "react";
import CalorieWisePNG from '../assets/new-images/CalorieWise.png';
import GlobalBridgePNG from '../assets/new-images/GlobalBridgeWork.png';
import HairomaMainPNG from '../assets/new-images/Hair_Food.png';
import TJURegBannerPNG from '../assets/new-images/TJU_Banner.png';
import VerdeFinancePNG from '../assets/imgages/VerdeFinance.png';
import ZeroToOneLabPNG from '../assets/new-images/ZeroToOneLabsLogo.png';
import ThinKarzPNG from '../assets/new-images/ThinkCarzLogo.png';
import DesignCozPNG from '../assets/imgages/DesignCoz.png';
import FigmaPNG from '../assets/tools/Figma.png';
import WebFlowPNG from '../assets/tools/WebFlow.png';
import HTMLPNG from '../assets/tools/HTML5.png';
import MiraBordPNG from '../assets/tools/MiraBord.png';
import AdobeCreativeCloudPNG from '../assets/tools/AdobeCreativeCloud.png';
import CSSPNG from '../assets/tools/CSS3.png';
import ChatGPTPNG from '../assets/tools/ChatGPT.png';
import NotionPNG from '../assets/tools/Notion.png';
import ZeplinPNG from '../assets/tools/Zeplin.png';


interface NavLink {
  id: React.Key,
  name: String,
  to: any,
};

export const appName: String = "Daiwat Thavai";

export const navLinks: Array<NavLink> = [
  {
    id: 1,
    name: "Home",
    to: "/",
  },
  {
    id: 2,
    name: "Work",
    to: "/work",
  },
  {
    id: 3,
    name: "About",
    to: "/about",
  },
  // {
  //   id: 4,
  //   name: "Resume",
  //   to: "/resume",
  // }
];

export const projects = [
  {
    id: 1,
    name: "Global Bridge",
    desc: "My master’s thesis explores creating a peer-driven support network at Thomas Jefferson University to help international students make safe and informed decisions while traveling solo in USA.",
    tools: ["User Research", "User Interviews", "Root cause analysis"],
    domain: "THESIS PREP",
    URL: "https://www.canva.com/design/DAGD21pQ0o4/krZZFQKn1jBvZOe5f3Sc7Q/view?utm_content=DAGD21pQ0o4&utm_campaign=designshare&utm_medium=link&utm_source=editor",
    imageURL: GlobalBridgePNG,
    bgColorClass: "bg_color4",
    show: false,
    isExternal: true
  },
  {
    id: 2,
    name: "Calorie Wise",
    desc: "Calorie Wise, a meal tracking app which gives instant calorie info, gives points for premium recipes.",
    tools: ["UX Research", "User Interviews", "Pretotyping"],
    domain: "DIGITAL DESIGN",
    URL: "calorie-wise",
    imageURL: CalorieWisePNG,
    bgColorClass: "bg_color1",
    show: false
  },
  {
    id: 3,
    name: "Hair Food",
    desc: "The goal was to enhance the Hair Food website by integrating e-commerce functionality and improving user experience, with the aim of potentially increasing business.",
    tools: ["UX Research", "Innovation", "User testing"],
    domain: "UX & UI",
    URL: "hair-food",
    imageURL: HairomaMainPNG,
    bgColorClass: "bg_color3",
    show: false
  },
  {
    id: 4,
    name: "Improving the Course Registration Experience",
    desc: "Project aimed to enhance the course registration process at Thomas Jefferson University for improved user experience and operational efficiency.",
    domain: "USER RESEARCH",
    tools: ["UX Research", "User Interviews", "Table Event"],
    URL: "tju",
    imageURL: TJURegBannerPNG,
    bgColorClass: "bg_color2",
    show: false
  }
];

export const experiences = [
  {
    id: 1,
    name: "Verde Finance",
    designation: "Product Design Intern",
    duration: "May 2023 - Aug 2023",
    role: "Designed Richie AI, increasing user engagement by 15% by enabling users to learn, plan, and track stocks and investments efficiently.  Collaborated closely with Agile development teams to ensure timely and successful product launches.",
    URL: "/VerdeFinance",
    imageURL: VerdeFinancePNG,
    show: false,
    style: { height: '30px' }
  },
  {
    id: 2,
    name: "ZeroToOne Labs",
    designation: "UI /UX Designer",
    duration: "Jun 2022 - Nov 2022",
    role: "Independently designed the Saadhan app for Tata, applying user-centered design principles to create an intuitive experience. Additionally, contributed to various projects for Tata, including the Drishti initiative, enhancing user engagement and aligning with the brand's commitment to innovation.",
    URL: "/ZeroToOneLabs",
    imageURL: ZeroToOneLabPNG,
    show: false,
    style: { height: '50px' }
  },
  {
    id: 3,
    name: "ThinKarz",
    designation: "Product Designer",
    duration: "Sep 2021 - May 2022",
    role: "Designed the Dex mobile application from scratch, conducting over 5 user testing sessions that led to a 40% improvement in usability scores.",
    URL: "/",
    imageURL: ThinKarzPNG,
    show: false,
    style: { height: '40px' }
  },
  {
    id: 4,
    name: "DesignCoz",
    designation: "UX Designer",
    duration: "Aug 2020 - Feb 2021",
    role: "Designed 20+ high-fidelity screens for the Technician module of a Wify brand, resulting in a 30% reduction in task completion time and a 25% increase in user satisfaction scores.",
    URL: "/DesignCoz",
    imageURL: DesignCozPNG,
    show: false,
    style: { height: '60px' }
  },
];

export const tools = [
  {
    id: 1,
    name: "Figma",
    type: "Design Tool",
    imageURL: FigmaPNG,
  },
  {
    id: 2,
    name: "Webflow",
    type: "Website Builder",
    imageURL: WebFlowPNG,
  },
  {
    id: 3,
    name: "HTML",
    type: "Code",
    imageURL: HTMLPNG,
  },
  {
    id: 4,
    name: "Miro Board",
    type: "UX and Brainstorming",
    imageURL: MiraBordPNG,
  },
  {
    id: 5,
    name: "Adobe Creative Cloud",
    type: "Design Tools",
    imageURL: AdobeCreativeCloudPNG,
  },
  {
    id: 6,
    name: "CSS",
    type: "Code",
    imageURL: CSSPNG,
  },
  {
    id: 7,
    name: "Chat GPT",
    type: "Brainstorming tool",
    imageURL: ChatGPTPNG,
  },
  {
    id: 8,
    name: "Notion",
    type: "Documentation",
    imageURL: NotionPNG,
  },
  {
    id: 9,
    name: "Zeplin",
    type: "Design handoff",
    imageURL: ZeplinPNG,
  }
];

export const resumeLink = "https://drive.google.com/file/d/1yHfUz8fi5Lgn7moBvPubX3SMfzCGth8Z/view?usp=sharing";