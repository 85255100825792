import React, { lazy, Suspense, useEffect, useRef } from 'react';
import styles from './index.module.css';
import Prototype from '../../../assets/new-images/HairFoodNewLanding.png';
import arrowImage from '../../../assets/imgages/arrowImage.png'
import UserJourneyMap from '../../../assets/imgages/UserJourneyMap.png'
import Image from '../../../assets/new-images/HairFoodLogo.png';
import mac4 from '../../../assets/imgages/mac4.png';
import mac5 from '../../../assets/imgages/mac5.png';
import DesignProcessMobile from '../../../assets/new-images/DesignProcessMobile.png';
import mac51 from '../../../assets/new-images/mac5.1.png';
import mac6 from '../../../assets/new-images/mac6.png';
import tjuPNG from '../../../assets/new-images/TJU_Banner.png'
import Header from '../../Header';
import NextCase from '../../NextCase';
import arrowPng from '../../../assets/new-images/Arrow_14.png';
import SectionNavigation from '../../SectionNavigation';
import Video from '../../Video';
import hairomaHomepage from '../../../assets/videos/HairomaMainPage.mov';
import hairomaSelectFragrance from '../../../assets/videos/SelectFragrance.mov';
import hairomaCheckoutProcess from '../../../assets/videos/CheckoutProcess.mov';
import solutionandImpactHairfood from '../../../assets/videos/SolutionandImpactHairfood.mp4';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { useMedia } from 'react-use';
import ReactGA from "react-ga4";

const ImageComponent = lazy(() => import('../../ImageComponent'))

const HairFood: React.FC = () => {
  const isMobileSize = useMedia("(max-width: 480px)");

  const topRef = useRef<any>(null);
  const sectionButtionRef = useRef<any>(null);
  const observedDivRef = useRef<HTMLDivElement>(null);
  useIntersectionObserver(sectionButtionRef, observedDivRef);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const videoRef = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null)
  ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const videoContainerRef = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null)
  ]

  const sectionRefs = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
  ];

  const arrayToMap = ['Problem', 'Solution', 'Process', 'Research', 'Results', 'Takeways'];
  const hairFoodLink = 'https://www.figma.com/proto/HIqJkMxVburnWUFod1avrE/Hair-%26-Food-(Daiwat)?page-id=185%3A11&node-id=606-44623&node-type=frame&viewport=990%2C-1810%2C0.06&t=ovuKfzwoAdVM9SiU-1&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=606%3A44623'

  const playVideo = (videoPlayContainerRef: any, videoPlayRef: any) => {
    // Create a new IntersectionObserver instance
    console.log('playVideo', videoPlayContainerRef)
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && videoPlayRef.current) {
          videoPlayRef.current.currentTime = 0; // Set the video time to the beginning
          videoPlayRef.current.play(); // Play the video from the start
        } else if (videoPlayRef.current) {
          videoPlayRef.current.pause(); // Pause the video
        }
      });
    }, {
      root: null, // Use the viewport as the root
      threshold: 0.1 // Trigger when 10% of the observed element is in view
    });
    // Start observing the current video container
    if (videoPlayContainerRef.current) {
      observer.observe(videoPlayContainerRef.current);
    }
    // Clean up the observer when unmounting
    return () => {
      if (videoPlayContainerRef.current) {
        observer.unobserve(videoPlayContainerRef.current);
      }
    };
  }

  useEffect(() => {
    videoContainerRef.forEach((videoContainerRefEle, index) => {
      const cleanup = playVideo(videoContainerRefEle, videoRef[index]);
      return cleanup;
    });
  }, [videoContainerRef, videoRef]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "Design Coz", title: "Design Coz" });
  });
  return (
    <section>
      {/* <Loader /> */}
      <Header />
      <section className={`${styles.main}`}>
        <div ref={observedDivRef} className={styles.blueDiv}>
          <img src={Prototype} alt="Descriptive text" className={styles.image} />
        </div>
        <a onClick={() => {
          ReactGA.event({
            category: "Hair Food",
            action: "click view prototype",
            label: "Hair food prototype"
          });
        }} href={hairFoodLink} target='_blank' className={styles.circle} rel="noreferrer">
          View<br></br>Prototype
        </a>
      </section>
      {/* Section one done start */}
      <section ref={topRef} >
        <div className={styles.topContainer}>
          <span className={styles.title}>Hair Food</span>
          <div className={styles.tagContainer}>
            <span className={styles.tag}>Product Design</span>
            <span className={styles.tag}>UXR</span>
            <span className={styles.tag}>User Testing</span>
          </div>
        </div>
      </section>

      <section >
        <div className={styles.container}>

          <div className={styles.item} >
            <div className={styles.sectionTitle}>Role</div>
            <div className={styles.line}></div>
            <div className={styles.paragraph}>Product Designer</div >
          </div>

          <div className={styles.item} >
            <div className={styles.sectionTitle}>What I Did</div>
            <div className={styles.line}></div>
            <div className={styles.paragraphTwo}>Recruiting Users, User Interviews, UXR,
              UX Design, Visual identity, UI Design, Usability Testing</div >
          </div>

          <div className={styles.item} >
            <div className={styles.sectionTitle}>Time line</div>
            <div className={styles.line}></div>
            <div className={styles.paragraph}>3 Months</div >
          </div>

        </div>
      </section>


      {/* Section 3 done */}
      <section data-aos="fade-up" className={`container-data ${styles.containerSection3}`}>
        <div className={styles.section3LeftContainer}>
          <span className={styles.sectionTitle}>Goal</span>
          <div className={styles.section3GoalDescription}>
            The goal was to enhance the Hair Food website by adding e-commerce functionality and improving user experience, potentially increasing business.
          </div >
        </div>

        <div className={styles.section3RightContainer}>
          <span className={styles.sectionTitle}>Tools</span>
          <div className={styles.section3ToolsContainer} >
            <span className={styles.tag2}>Usability Testing</span>
            <span className={styles.tag2}>Figjam</span>
            <span className={styles.tag2}>User Interviews</span>
            <span className={styles.tag2}>MiroBoard</span>
            <span className={styles.tag2}>Google Surveys</span>
            <span className={styles.tag2}>Figma</span>
          </div>
        </div>
      </section>

      <section ref={sectionRefs[0]} data-aos="fade-up" className={`container-data ${styles.containerSection3}`}>
        <div className={styles.section3ProblemStatementContainer}>
          <span className={styles.sectionTitle}>Problem Statement</span>
          <div className={styles.section3ProblemStatementContent}>
            <div className={styles.section3ProblemPoint}>
              <div className={styles.section_circle}>•</div>
              <div>User interviews revealed a strong initial interest in organic hair care products among customers.</div>
            </div >
            <div className={styles.section3ProblemPoint}>
              <div className={styles.section_circle}>•</div>
              <div><b>58%</b>find it challenging to maintain consistent hair care routines and brand loyalty.</div>
            </div >
            <div className={styles.section3ProblemPoint}>
              <div className={styles.section_circle}>•</div>
              <div><b>93.2%</b> of participants prefer online purchases for familiar products.</div>
            </div >
          </div>
        </div>

        <div className={styles.section3ChallengeContainer}>
          <span className={styles.sectionTitle}>Challenge</span>
          <div className={styles.section3ChallengeContent}>
            <div className={styles.section3ChallengeText}>
              How can we offer Hair Food customers a <span className={styles.section3ChallengeTextBold}>convenient</span> and <span className={styles.section3ChallengeTextBold}>secure</span> online shopping experience while also encouraging <span className={styles.section3ChallengeTextBold}>increased</span> shopping frequency?
            </div >
          </div>
        </div>
      </section>

      {/* Section 4 done */}
      <section ref={videoContainerRef[0]} data-aos="fade-up" className={`container-data ${styles.section4Container}`}>
        <div ref={sectionRefs[1]} className={styles.section4SolutionContainer} >
          <span className={styles.sectionTitle}>Solution and Impact</span>
          <div className={styles.section4SolutionContent}>
            <div className={styles.section4SolutionText}>
              <b>Hairoma:</b> Customize your Hair Food experience with personalized ingredient and fragrance ratios. Enhance engagement, loyalty, and security for a seamless shopping journey, encouraging frequent visits.
            </div >
            <div className={styles.section4ImpactText}>
              <b>Impact:</b> Customize your Hair Food experience with personalized ingredient and fragrance ratios. Enhance engagement, loyalty, and security for a seamless shopping journey, encouraging frequent visits.
            </div >
          </div>
        </div>

        <div className={styles.section4ImageContainer} data-aos="fade-up">
          <Video src={solutionandImpactHairfood} width={isMobileSize ? "100%" : "700"} height={isMobileSize ? "100%" : "400"} videoRef={videoRef[0]} />
        </div>
      </section>

      {/* Section 4 done end */}

      <section ref={sectionRefs[2]} className={`container-data-small ${styles.section5Container} ${styles.design_outcome_caontainer}`}>
        <div className={`container-data-small ${styles.section5ContainerClass}`}>
          <div ref={videoContainerRef[1]} className={styles.section5ContentContainerOne} data-aos="fade-up">
            <Video src={hairomaHomepage} width={isMobileSize ? "100%" : "600"} height={isMobileSize ? "100%" : "399"} videoRef={videoRef[1]} />

            <div className={styles.section5TextContainer} >
              <span className={styles.section5DesignOutcomesTitle}>Design outcomes</span>

              <span className={styles.section5HairomaTitle}>Hairoma - Home Page</span>
              <div className={styles.section5TextContent}>
                <div className={styles.section5Text}>
                  The "Hairoma" page is exclusively dedicated to empowering users to tailor their preferred fragrance to perfection.
                </div >
                <div className={styles.section5Text}>
                  The brand has introduced a clear, step-by-step guide to streamline the fragrance customization journey, enhancing user understanding and navigation.
                </div >
                <div className={styles.section5Text}>
                  User feedback is crucial for building trust and connections with customers, providing insights into their fragrance choices and ensuring transparency in our offerings.
                </div >
              </div>
            </div>
          </div>
          <div ref={videoContainerRef[2]} className={`${styles.section5ContentContainerOne} ${styles.section5ContentContainer}`} data-aos="fade-up">
            <Video src={hairomaSelectFragrance} width={isMobileSize ? "100%" : "600"} height={isMobileSize ? "100%" : "399"} videoRef={videoRef[2]} />
            <div className={styles.section5TextContainer}>
              <span className={styles.section5ChooseScentTitle}>Choose Your Signature Scent</span>
              <div className={styles.section5TextContent}>
                <div className={styles.section5Text}>
                  Users can personalize their hair products by selecting from a range of unique scents, including Avocado, Orange, Lavender, and Mango.
                </div >
                <div className={styles.section5Text}>
                  The customization process allows users to blend different fragrances, tailoring the scent to their individual preferences.
                </div >
                <div className={styles.section5Text}>
                  This feature enhances the product experience, making it uniquely suited to each user's taste and style.
                </div >
              </div>
            </div>
          </div>
          <div ref={videoContainerRef[3]} className={`${styles.section5ContentContainerOne} ${styles.section5ContentContainer}`} data-aos="fade-up">
            <Video src={hairomaCheckoutProcess} width={isMobileSize ? "100%" : "600"} height={isMobileSize ? "100%" : "399"} videoRef={videoRef[3]} />
            <div className={styles.section5TextContainer}>
              <span className={styles.section5CheckoutTitle}>Effortless Checkout</span>
              <div className={styles.section5TextContent}>
                <div className={styles.section5Text}>
                  Enjoy a seamless checkout process tailored for your convenience, ensuring a hassle-free shopping experience.
                </div >
                <div className={styles.section5Text}>
                  Quickly review your selected items, enter your details with ease, and make any final adjustments before completing your purchase.
                </div >
                <div className={styles.section5Text}>
                  Complete your transaction in just a few simple steps, allowing you to finalize your order quickly and efficiently.
                </div >
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.arrowContainer}`} data-aos="fade-up">
        <span className={styles.sectionTitle}>Design Process</span>
        <Suspense fallback={<div>Loading image...</div>}>
          <ImageComponent src={isMobileSize ? DesignProcessMobile : arrowImage} className={styles.UserJourneyMap} />
        </Suspense>
      </section>

      <section ref={sectionRefs[3]} data-aos="fade-up" className={`container-data ${styles.sectionReasarchContainer}`}>
        <div className={styles.section3ProblemStatementContainer}>
          <span className={styles.sectionTitle}>Research Goals </span>
          <div className={styles.section3ProblemStatementContent}>
            <div className={styles.sectionResearchPoint}>
              <div className={styles.section_circle}>•</div>
              <div>Understand how often and why product procurement is necessary.</div>
            </div >
            <div className={styles.sectionResearchPoint}>
              <div className={styles.section_circle}>•</div>
              <div>Identify user preferences to prioritize and improve the customer experience.</div>
            </div >
            <div className={styles.sectionResearchPoint}>
              <div className={styles.section_circle}>•</div>

              <div>Create a simple, user-friendly e-commerce platform to boost sales.</div>
            </div >
          </div>
        </div>
        <div className={styles.section3ChallengeContainer}>
          <span className={styles.sectionTitle}>Research Objectives</span>
          <div className={styles.section3ChallengeContent}>
            <div className={styles.sectionReasarchText}>
              To understand the thought process and purchase decisions involved in buying a hair care product.
            </div >
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.hair_food_margin_top}`} ref={sectionRefs[4]} data-aos="fade-up">
        <span className={styles.boxgridTitle}>
          User Interview Highlights
        </span>

        <div className={styles.boxgridContainer}>
          <div className={styles.boxgridLeft}>
            <div className={styles.boxgridParagraph}>
              <div className={styles.section_circle}>•</div>
              <div>User interviews revealed a <span className={styles.boxgridParagraphBold}>strong initial interest</span> in organic hair care products among customers.</div>
            </div >
            <div className={styles.boxgridParagraph} style={{ marginTop: '16px' }}>
              <div className={styles.section_circle}>•</div>
              <div>Competitive brands appeared to offer similar formulations, leading to a perceived <span className={styles.boxgridParagraphBold}>lack of uniqueness.</span></div>
            </div >
            <div className={styles.boxgridParagraph} style={{ marginTop: '24px' }}>
              <div className={styles.section_circle}>•</div>
              <div>Exploring innovative <span className={styles.boxgridParagraphBold}>ingredient combinations</span> and effective communication of unique value propositions could potentially <span className={styles.boxgridParagraphBold}>revitalize</span> customer <span className={styles.boxgridParagraphBold}>loyalty</span> and <span className={styles.boxgridParagraphBold}>enhance</span> Hair Food’s competitive position.</div>
            </div >
          </div>
          <div className={styles.boxgridRight}>
            <div className={styles.boxgridSquare} >
              <span className={styles.boxgridPercent}>92%</span>
              <span className={styles.boxgridDesc}>Prefer Organic or natural Products</span>
            </div>
            <div className={styles.boxgridSquare} >
              <span className={styles.boxgridPercent}>72%</span>
              <span className={styles.boxgridDesc}>May Switch to Hair Food</span>
            </div>
            <div className={styles.boxgridSquare} >
              <span className={styles.boxgridPercent}>27</span>
              <span className={styles.boxgridDesc}>Survey Response</span>
            </div>
            <div className={styles.boxgridSquare} >
              <span className={styles.boxgridPercent}>5</span>
              <span className={styles.boxgridDesc}>In-Person Interviews</span>
            </div>
          </div>
        </div>
      </section>

      <section data-aos="fade-up" className={`container-data ${styles.hair_food_margin_top}`}>
        <span className={`${styles.boxgridTitle} ${styles.userJourneyMapTitle}`}>
          User Journey map
        </span>
        <Suspense fallback={<div>Loading image...</div>}>
          <ImageComponent src={UserJourneyMap} className={styles.UserJourneyMap} />
        </Suspense>

        <div className={styles.UserJourneyTextLinkConatiner}>
          <span className={styles.UserJourneyTextLink}>
            link for MiroBoard
          </span>
        </div>
      </section>

      {/* Section 9 done */}
      <section className={`container-data-small ${styles.hair_food_margin_top}`}>
        <div className={styles.section9Container} data-aos="fade-up">
          <span className={`${styles.section9FirstTitle} ${styles.uppercaseText}`} data-aos="fade-up">
            Usability testing (Initial Insights)
          </span>
          <div className={styles.section9Content}>
            <div className={styles.section9ImageBlock} data-aos="fade-up">
              <Suspense fallback={<div>Loading image...</div>}>
                <ImageComponent src={mac4}
                  alt="Left Image"
                  className={styles.section9Image} />
              </Suspense>
              <div className={styles.arrowClass}>
                <img src={arrowPng} alt='' />
              </div>
              <div className={styles.section9TextBlock} data-aos="fade-up">
                <div className={styles.section9Text}>
                  The participants found it difficult to understand the sequence of fragrances to be selected from the collection.
                </div >
              </div>
            </div>
            <div className={`${styles.section9ImageBlock} ${styles.section9ImageBlockMargin}`} data-aos="fade-up">
              <Suspense fallback={<div>Loading image...</div>}>
                <ImageComponent src={mac5}
                  alt="Right Image"
                  className={styles.section9Image} />
              </Suspense>

              <div className={styles.section9TextBlock} data-aos="fade-up">
                <div className={styles.section9Text}>
                  The participants mentioned that they would like to set their preferred fragrance note from the two fragrances they selected.
                </div >
              </div>
            </div>
          </div>
          <span className={`${styles.section9FirstTitle} ${styles.uppercaseText}`} data-aos="fade-up">Post-Implementation Feedback</span>
          <div className={styles.section9Content} data-aos="fade-up">
            <div className={styles.section9ImageBlock}>
              <Suspense fallback={<div>Loading image...</div>}>
                <ImageComponent src={mac6}
                  alt="Left Image"
                  className={styles.section9Image} />
              </Suspense>

              <div className={styles.section9TextBlock} data-aos="fade-up">
                <div className={styles.section9Text}>
                  An Active state was introduced to guide users in the fragrance selection process, making the starting point clear and simplifying their choices.
                </div >
              </div>
            </div>
            <div className={`${styles.section9ImageBlock} ${styles.section9ImageBlockMargin}`}>
              <Suspense fallback={<div>Loading image...</div>}>
                <ImageComponent src={mac51}
                  alt="Right Image"
                  className={styles.section9Image} />
              </Suspense>
              <div className={styles.section9TextBlock} data-aos="fade-up">
                <div className={styles.section9Text}>
                  A fragrance ratio feature was introduced, allowing users to customize their products with their desired fragrance blend. This enhancement lets users select and adjust the proportion of different scents.
                </div >
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.section10ContainerMain} ref={sectionRefs[5]} >
        <div className={`container-data ${styles.section10ContainerMain2}`}>
          <span className={`${styles.section10Heading} ${styles.uppercaseText}`}>Takeaways</span>
          <div className={styles.section10Container}>
            <div className={styles.section10LeftContent} data-aos="fade-up">
              <div className={styles.section10DescriptionContainer}>
                <div className={styles.section10Item}>
                  <b>•</b>
                  <div className={styles.section10Text}>
                    By employing various methodologies, we gained a clear and targeted understanding of the primary issue, avoiding distractions from non-essential concerns.
                  </div >
                </div>
                <div className={styles.section10Item}>
                  <b>•</b>
                  <div className={styles.section10Text}>
                    The development of an adaptable eCommerce website design that complies with accessibility standards demonstrated an effective solution approach.
                  </div >
                </div>
                <div className={styles.section10Item}>
                  <b>•</b>
                  <div className={styles.section10Text}>
                    The collaborative nature of this project highlighted the importance of unified teamwork, effective project management, and utilizing the distinct skills of each team member to meet shared objectives.
                  </div >
                </div>
              </div>
            </div>
            <div className={styles.section10RightContent} data-aos="fade-up"  >
              <Suspense fallback={<div>Loading image...</div>}>
                <ImageComponent src={Image}
                  alt="Takeaways Image"
                  className={styles.section10Image} />
              </Suspense>
            </div>
          </div>
        </div>
      </section>
      <SectionNavigation sectionButtionRef={sectionButtionRef} arrayToMap={arrayToMap} sectionRefs={sectionRefs} topRef={topRef} />
      <NextCase sectionButtionRef={sectionButtionRef} caseName="Improving the Course Registration Experience" redirectUrl="/tju" imageUrl={tjuPNG} />
    </section>
  );
};

export default HairFood;
