import React, { useEffect, useRef } from 'react'
import ReactGA from "react-ga4";
import Header from '../Header';
import styles from './index.module.css';
import VFLandingLogo from '../../assets/new-images/VFLandingLogo.png';
import Group1 from '../../assets/new-images/VFGroup1.png'
import Group2 from '../../assets/new-images/VFGroup2.png'
import Group3 from '../../assets/new-images/VFGroup3.png'
import UserFlow from '../../assets/new-images/VFUserFlow.png'
import VFUIThemeDarkTheme from '../../assets/new-images/VFUIThemeDarkTheme.png'
import VFUIThemeLightTheme from '../../assets/new-images/VFUIThemeLightTheme.png'
import VFETheme from '../../assets/new-images/VFETheme.png'
import VFNTheme from '../../assets/new-images/VFNTheme.png'
import ZeroToOneLabsLogo from '../../assets/new-images/ZeroToOneLabs1.png'
import VFLogo from '../../assets/new-images/VFLogo.png'
import Stepper from '../Stepper';
import NextCase from '../NextCase';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Paragraph from './Paragraph';
const data = [
  {
    image: Group1,
    text: 'Anonymous User',
    paragraph: '"A tool that offers personalized recommendations or insights based on my portfolio performance, and helps me make quicker decisions, would be great."',
  },
  {
    image: Group2,
    text: 'Anonymous User',
    paragraph: '"It would be helpful to have a smart feature that understands my goals and offers proactive suggestions on how I can optimize my investments."',
  },
  {
    image: Group3,
    text: 'Anonymous User',
    paragraph: '"It would be amazing to have a feature that provides instant, personalized assistance for portfolio-related questions, especially during market changes."',
  },
];


function VerdeFinance() {
  const sectionButtionRef = useRef<HTMLDivElement>(null);
  const observedDivRef = useRef<HTMLDivElement>(null);
  useIntersectionObserver(sectionButtionRef, observedDivRef);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ZeroToOneLabs", title: "ZeroToOne Labs" });
  });
  return (
    <>
      <Header />
      <section className={`container-data ${styles.blueDiv} ${styles.VF__blueDiv_BC}`}>
        <div className={styles.image_container} data-aos="fade-up">
          <img src={VFLandingLogo} alt='Img 1' />
        </div>
        <div>
          <a onClick={() => {
            ReactGA.event({
              category: "TJU",
              action: "click view prototype",
              label: "TJU prototype"
            });
          }} href='#/' className={styles.circle} target='_blank' rel='noreferrer'>
            Website<br></br>Link
          </a>
        </div>
      </section>
      <section className={`container-data ${styles.vf_main_title} ${styles.vf_section_mt}`} data-aos="fade-up">
        Verde Finance
      </section>
      <section className={`container-data ${styles.stepper_container_main}`}>
        <Stepper name="Role" desc="Product Design Intern" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.vf_stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.vf_stepper_desc_width}`} />
        <Stepper name="What I Did" desc="User Research, Concept Testing User Flow, User Testing, High Fidelity, Color Theory" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.vf_stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.vf_stepper_desc_width}`} />
        <Stepper name="Time line" desc="3 Months" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.vf_stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.vf_stepper_desc_width}`} />
      </section>
      <section className={`container-data ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>About Verde finance</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            Verde Finance is a fintech company that provides users with powerful tools to track, manage, and optimize their investment portfolios. Focused on user-friendly design and real-time financial insights, the platform simplifies investment decisions for both beginners and experienced investors.
          </div>
        </div>

        <div className={`${styles.vf_right} ${styles.vf_mv_mr}`} data-aos="fade-up">
          <span className={styles.stepper_name}>Tools</span>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <span className={styles.vf_tool}>Usability Testing</span>
            <span className={styles.vf_tool}>Figma</span>
            <span className={styles.vf_tool}>MiroBoard</span>
            <span className={styles.vf_tool}>Google Form</span>
            <span className={styles.vf_tool}>Zeplin</span>
          </div>
        </div>
      </section>
      <section className={`container-data ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Problem Statement I</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <Paragraph mainText='Eye Strain' text=': Usability testing revealed that the current screens caused eye strain during prolonged use, with 30% of users reporting discomfort after just 10 minutes of use.' />
            <Paragraph mainText='Lack of Contrast' text=': The platform lacked sufficient contrast and readability, reducing overall comfort and accessibility for a significant portion of users, particularly those with visual impairments.' />
          </div>
        </div>

        <div className={`${styles.vf_right} ${styles.vf_mv_mr}`} data-aos="fade-up">
          <span className={styles.stepper_name}>Challenge</span>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <span className={styles.vf_challenge_text}>How might we apply color theory principles to improve contrast and readability, ensuring compliance with WCAG guidelines, to reduce eye strain and enhance accessibility for users, particularly those with visual impairments.</span>
          </div>
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_user_flow_container} data-aos="fade-up">
          <span className={styles.vf_user_flow_title}>UI Screens - Light Theme</span>
          <div className={styles.vf_user_flow_image_container}>
            <img src={VFETheme} alt='Img 1' />
          </div>
          <div className={styles.vf_section_mt}>
            <div className={styles.vf_user_flow_image_container}>
              <img src={VFNTheme} alt='Img 1' />
            </div>
          </div>
        </div>
      </section>
      <section className={`container-data ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Problem statement II</span>

          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <Paragraph mainText='Low Retention' text=': Users engage with their portfolios briefly and leave, resulting in low retention.' />
            <Paragraph mainText='Lack of Proactive Features' text=': The platform lacks personalized, proactive features, making the experience passive and reducing long-term engagement.' />
            <Paragraph mainText='Missed Engagement Opportunities' text=': Users do not receive timely insights or updates, limiting their ability to stay informed and actively manage their portfolios.' />
          </div>
        </div>

        <div className={`${styles.vf_right} ${styles.vf_mv_mr}`} data-aos="fade-up">
          <span className={styles.stepper_name}>Challenge</span>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <span className={styles.vf_challenge_text}>How might we introduce personalized and proactive features to keep users engaged for longer, provide timely insights, and encourage active portfolio management, ultimately improving retention?</span>
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>research objectives</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <Paragraph mainText='' text='The current user engagement on the platform shows that users spend only a short time checking their portfolios, resulting in low retention and limited interaction with proactive features' />
            <Paragraph mainText='' text='We aim to improve user engagement and retention by introducing personalized and proactive features for a more interactive and insightful experience.' />
            <Paragraph mainText='Our objectives include:' text='' />
            <div className={styles.vf_paragraph_container_inner_padding}>
              <Paragraph mainText='' text='Increasing user retention by encouraging longer and more frequent interactions with the platform.' />
              <Paragraph mainText='' text='Identifying and addressing user pain points that contribute to passive usage.' />
              <Paragraph mainText='' text='Providing personalized, proactive updates to keep users informed and engaged.' />
              <Paragraph mainText='' text='Improving overall user satisfaction through a more dynamic and tailored experience.' />
            </div>
          </div>
        </div>

        <div className={styles.vf_right} data-aos="fade-up">
          <span className={`${styles.stepper_name} ${styles.add_responsive_mr}`}>research Goals</span>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <Paragraph mainText='Understand user behavior' text=': Investigate why users spend only a short amount of time on the platform and identify factors contributing to low retention.' />
            <Paragraph mainText='Identify pain points' text=": Uncover the challenges users face that lead to passive usage and minimal interaction with the platform's features." />
            <Paragraph mainText='Explore user needs for personalization' text=': Assess user interest in personalized and proactive features, such as tailored insights and notifications.' />
            <Paragraph mainText='Improve user engagement' text=': Gather data to inform strategies for increasing user retention through longer and more frequent platform interactions.' />
            <Paragraph mainText='Enhance overall satisfaction' text=': Collect feedback to ensure the platform delivers a more dynamic, engaging, and tailored experience that meets users’ expectations.' />
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Secondary research</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <a href="https://www.iamdave.ai/blog/generative-ai-banks-retain-customers/?utm_source=chatgpt.com" target='_blank' rel="noreferrer"><div className={styles.vf_link_text}>
              🔗 User Engagement and Retention Strategies in Financial Apps
            </div></a>
            <a href="https://www.researchgate.net/publication/387133873_Personalized_Financial_Advisory_Services_Using_AI" target='_blank' rel="noreferrer"><div className={styles.vf_link_text}>
              🔗 Personalization in Financial Products
            </div></a>
            <a href="https://www.tapix.io/resources/post/motivate-users-increase-banking-app-usage?utm_source=chatgpt.com" target='_blank' rel="noreferrer"><div className={styles.vf_link_text}>
              🔗 Reducing Passive Usage in financial apps
            </div></a>
          </div>
        </div>

        <div className={`${styles.vf_right} ${styles.vf_mv_mr}`} data-aos="fade-up">
          <span className={styles.stepper_name}>Quantitative survey Link</span>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSeAjla1vSHu0vEJcN18zbIH5QhRG_LoLqyZ0UDeUmvqVYMUQg/viewform" target='_blank' rel="noreferrer"><div className={styles.vf_link_text}>
              https://docs.google.com/forms/d/e/1FAIpQLSeAjla1vSHu0vEJcN18zbIH5QhRG_LoLqyZ0UDeUmvqVYMUQg/viewform
            </div></a>
          </div>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <div className={styles.stepper_name} style={{ marginTop: "24px" }}>Partipants</div>
            <div className={`${styles.vf_tools_container} ${styles.vf_text}`} >
              We shared this Google Form with Verde Finance users to better understand their needs, identify pain points, and gather feedback on how to improve user engagement and satisfaction on the platform.
            </div>
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.vf_section_mt}`}>
        <span className={styles.stepper_name} data-aos="fade-up">Research Insights</span>
        <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
          {data.map((item, index) => (
            <div key={index} className={styles.vf_research_insight_container} data-aos="fade-up">
              <div className={styles.vf_research_insight_image_container} >
                <img src={item.image} alt={`Descriptive text ${index + 1}`} className={styles.vf_research_insight_small_image} />
                <div className={styles.vf_research_insight_small_text}>{item.text}</div>
              </div>
              <div className={styles.vf_research_insight_paragraph_container} >
                <div className={styles.vf_research_insight_paragraph}>
                  {item.paragraph}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_user_flow_container} data-aos="fade-up">
          <span className={styles.vf_user_flow_title}>User Flow</span>
          <div className={styles.vf_user_flow_image_container}>
            <img src={UserFlow} alt='Img 1' />
          </div>
        </div>
      </section>

      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_user_flow_container} data-aos="fade-up">
          <span className={styles.vf_user_flow_title}>UI Screens - Light Theme</span>
          <div className={styles.vf_user_flow_image_container}>
            <img src={VFUIThemeLightTheme} alt='Img 1' />
          </div>
          <div className={styles.vf_section_mt}>
            <span className={styles.vf_user_flow_title}>UI Screens - Dark Theme</span>
            <div className={styles.vf_user_flow_image_container}>
              <img src={VFUIThemeDarkTheme} alt='Img 1' />
            </div>
          </div>
        </div>
      </section>

      <section className={`${styles.vf_section_mt}`}>
        <span className={`container-data ${styles.stepper_name}`}>Takeways</span>
        <div className={styles.vf_takeWays_container_main}>
          <div className={`container-data-small  ${styles.vf_about_vf_container_takeways} ${styles.vf_takeWays_container}`}>
            <div className={`${styles.vf_left} ${styles.vf_takeways_padding_left}`} data-aos="fade-up">
              <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
                <Paragraph mainText='Research-Driven Design' text=': User insights played a pivotal role in shaping engagement strategies, ensuring that new features directly addressed user pain points and behavior patterns.' />
                <Paragraph mainText='Understanding User Expectations' text='s: By understanding how users interact with the platform, we identified opportunities to introduce proactive updates and personalized insights to drive engagement.' />
                <Paragraph mainText='Enhancing Engagement Through Experience' text='Experience: The focus was on creating an intuitive and interactive experience that encourages users to explore beyond basic portfolio checks.' />
              </div>
            </div>
            <div className={`${styles.vf_right} ${styles.vf_logo_image_container_main}`} data-aos="fade-up">
              <div className={`${styles.vf_logo_image_container}`} >
                <img src={VFLogo} alt='Img 1' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <NextCase sectionButtionRef={sectionButtionRef} caseName='TATA Fleet Edge' redirectUrl="/ZeroToOneLabs" imageUrl={ZeroToOneLabsLogo} />

    </>
  )
}

export default VerdeFinance