import gsap from "gsap";
import React, { useEffect, useState } from "react";
import styles from "./index.module.css";

import upperButton from "../../assets/imgages/upperButton.png";

function SectionNavigation({
  arrayToMap,
  sectionRefs,
  topRef,
  sectionButtionRef,
}) {
  const [selectedButton, setSelectedButton] = useState(0);
  const handleButtonClick = (index) => {
    sectionRefs[index].current?.scrollIntoView({ behavior: "smooth" });
    setSelectedButton(index);
  };

  const handleScroll = () => {
    sectionRefs.forEach((ref, index) => {
      const sectionTop = ref.current?.getBoundingClientRect()?.top;
      if (
        sectionTop !== undefined &&
        sectionTop >= 0 &&
        sectionTop < window.innerHeight / 2
      ) {
        setSelectedButton(index);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    gsap.to(sectionButtionRef.current, {
      opacity: 0,
      duration: 0.5,
      delay: 0.5,
    });
    return () => {
      gsap.to(sectionButtionRef.current, {
        opacity: 0,
        duration: 0.5,
        delay: 0.5,
      });
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className={styles.centerButtons} ref={sectionButtionRef}>
      <div className={styles.buttonsContainer}>
        {arrayToMap.map((title, buttonIndex) => (
          <button
            key={buttonIndex}
            className={`${styles.button} ${
              selectedButton === buttonIndex ? styles.selectedButton : ""
            }`}
            onClick={() => handleButtonClick(buttonIndex)}
          >
            {title}
          </button>
        ))}
        <button
          className={styles.circleButton}
          onClick={() => topRef.current?.scrollIntoView({ behavior: "smooth" })}
        >
          <img src={upperButton} className={styles.section5Image} alt="" />
        </button>
      </div>
    </section>
  );
}

export default SectionNavigation;
