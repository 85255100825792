import React, { useCallback, useEffect } from 'react';
import gsap from 'gsap';

import styles from './index.module.css';
import { experiences } from '../../utils/constants';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";

function MyJourney() {
  // added version/v2.0 to prod
  // Function to show the div (animate from bottom to top)
  function hideExp(elementId: any, parentDiv: any, myJouneyProject: any) {
    gsap.fromTo(elementId, { y: "100%", display: 'none' }, { y: "0%", display: 'block', duration: 0.6 });
    gsap.fromTo(myJouneyProject, { y: "10%" }, { y: "0%", duration: 0.4 });
    gsap.fromTo(parentDiv, { y: "10%", height: "479px" }, { y: "0%", height: "600px", borderColor: '#3C52E6', borderWidth: '2px', duration: 0.4 });

  };
  // Function to hide the div (animate from top to bottom)
  function showExp(elementId: any, parentDiv: any, myJouneyProject: any) {
    gsap.fromTo(elementId, { y: "0%", display: 'block' }, { y: "100%", display: 'none', duration: 0.6 });
    gsap.fromTo(myJouneyProject, { y: "0%" }, { y: "10%", duration: 0.4 });
    gsap.fromTo(parentDiv, { y: "0%", height: "600px" }, { y: "10%", height: "479px", borderColor: '#89898980', borderWidth: '1px', duration: 0.4 });
  }
  const showHideExperience = useCallback((tag: any, idx: any) => {
    const elementId = "#experience" + idx;
    const parentDiv = "#homepage_card_container" + idx;
    const myJouneyProject = '.my_journey_project' + idx;
    ReactGA.send({ hitType: `experience ${tag}`, page: "/", title: "Home" });
    if (tag === 'hide') {
      hideExp(elementId, parentDiv, myJouneyProject);
    }
    if (tag === 'show') {
      showExp(elementId, parentDiv, myJouneyProject);
    }
  }, [])
  useEffect(() => {
    showHideExperience('show', 0);
    showHideExperience('show', 1);
    showHideExperience('show', 2);
    showHideExperience('show', 3);
  }, [showHideExperience])
  return (
    <section className='homepage_my_work_container'>
      <div className={styles.homepage_my_work_title}>
        <span>MY JOURNEY</span>
      </div>
      <div className={styles.homepage_my_journey_container}>
        {experiences.map((experience, idx) => {
          return (
            <Link to={experience.URL} id={'homepage_card_container' + idx} key={experience.name} className={`${styles.homepage_card_container} ${styles.grow} cursor`} onMouseLeave={() => showHideExperience('show', idx)} onMouseEnter={() => showHideExperience('hide', idx)}>
              <div className={`${styles.homepage_exe_logo_name} ${styles.homepage_content}`}>
                <div className={'my_journey_project' + idx}>
                  <img alt='image-attr' style={experience.style} src={experience.imageURL}></img>
                </div>
                <div className={`${styles.homepage_exe_name} ${'my_journey_project' + idx}`}>{experience.name}</div>
              </div>
              <div className={`${styles.homepage_exe_d_duration} ${styles.homepage_content}`}>
                <div className={`${styles.homepage_exe_designation} ${'my_journey_project' + idx}`}>{experience.designation}</div>
                <div className={`${styles.homepage_exe_duration} ${'my_journey_project' + idx}`}>{experience.duration}</div>
              </div>
              <div className={styles.homepage_exe_role} id={'experience' + idx}>{experience.role}</div>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default MyJourney