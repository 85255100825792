import styles from './index.module.css';
import logoSVG from '../../assets/imgages/logo-small.png'
import GetInTouch from '../GetInTouch';
import FooterInfo from './FooterInfo';
import { useMedia } from 'react-use';

function Footer() {
  const isMobileSize = useMedia("(max-width: 480px)");

  return (
    <section className={`container-data ${styles.footer_container}`}>
      <div className={styles.footer_padding}>
        {isMobileSize ? (
          <div>
            <span data-aos="fade-up">
              <img className={styles.footer_logo} src={logoSVG} alt='logo'></img>
            </span>
            <br></br>
            <br></br>
            <span className={styles.footer_work_together}>Let’s work</span>
            <br></br>
            <span className={styles.footer_work_together} data-aos="fade-up">together</span>
          </div>
        ) : (<div>
          <span data-aos="fade-up">
            <img className={styles.footer_logo} src={logoSVG} alt='logo'></img>
            <span className={styles.footer_work_together}>&nbsp;&nbsp;Let’s work</span>
          </span>
          <br></br>
          <span className={styles.footer_work_together} data-aos="fade-up">together</span>
        </div>)}
        <a href='mailto:daiwat.work@gmail.com'>
          <GetInTouch name="Get in touch" id="footer-element" />
        </a>
        {/* <div className={styles.footer_contact_conatiner}>
          <a title='daiwat.work@gmail.com' href='mailto:daiwat.work@gmail.com'>
            <AnimatedButton name='daiwat.work@gmail.com' id='footer_contact' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class footer_contact_info_btn_border foote_btn_font_style footer_btn_height_width_style' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle footer_btn_height_width_style' />
          </a>
          <AnimatedButton name='+215-930-3964' id='foote_email' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class footer_contact_info_btn_border foote_btn_font_style footer_contact_info_btn_number' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle footer_contact_info_btn_number' />
        </div> */}
      </div>
      <div className={styles.info_main_container}>
        <FooterInfo />
      </div>
    </section>
  )
}

export default Footer