import React from 'react';

import styles from './index.module.css';
import AnimatedButton from '../AnimatedButton';
import { Parallax } from '../Parallex';


function GetInTouch({ nameClassName, name, id, borderClass, showParallex = true, linkClassName = "get_in_touch_class" }: any) {
  return (

    <div className={styles.footer_getintouch_container}>
      {showParallex &&
        <Parallax speed={0.6} className={styles.zIndex}>
          <AnimatedButton name={name} circleColor="#334BD3" id={id} mouseEnterTop="130%" mouseEnterToScale={5} linkClassName={linkClassName} circleClassName='about_common_circle_class about_me_button_circle' />
        </Parallax>
      }
      {!showParallex &&
        <AnimatedButton name={name} circleColor="#334BD3" id={id} mouseEnterTop="130%" mouseEnterToScale={5} linkClassName={linkClassName} nameClassName={nameClassName} circleClassName='about_common_circle_class about_me_button_circle' />
      }

      <div className={`${styles.footer_getintouch_border} ${borderClass ? borderClass : ''}`}>
      </div>
    </div>
  )
}

export default GetInTouch