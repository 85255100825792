import React, { useEffect, useRef } from 'react'
import ReactGA from "react-ga4";
import styles from './index.module.css';
import GlobalBridgeHeroImg from '../../assets/new-images/GBHeroImg.png';
import GBBackDrop from '../../assets/new-images/GBBackDrop.png'
import GBSaiTesting from '../../assets/new-images/GBSaiTesting.png'
import GBBottomLogo from '../../assets/new-images/GBBottomLogo.png'
import GBSevMatrix from '../../assets/new-images/GBSevMatrix.png'
import GBGlobalBridgeJeffersonUniversity from '../../assets/new-images/GBGlobalBridgeJeffersonUniversity.png'
import GBBookCover1 from '../../assets/new-images/GBBookCover1.png'
import GBDaiwatsPortfolio2025 from '../../assets/new-images/GBDaiwatsPortfolio2025.png'
import GBYathinReddy from '../../assets/new-images/GBYathinReddy.png'
import GBStyleGuide from '../../assets/new-images/GBStyleGuide.png'
import GBUIScreens from '../../assets/new-images/GBUIScreens.png'
import GBSiriDesigns from '../../assets/new-images/GBSiriDesigns.png'
import GBiWatchDesigns from '../../assets/new-images/GBiWatchDesigns.png'
import GBPhoneface1 from '../../assets/new-images/GBPhoneface1.png'
import GBPhoneface2 from '../../assets/new-images/GBPhoneface2.png'
import GBPhoneface3 from '../../assets/new-images/GBPhoneface3.png'
import GBPhoneface4 from '../../assets/new-images/GBPhoneface4.png'
import GBHMV from '../../assets/new-images/GBHMV.png'
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Header from '../Header';
import Stepper from '../Stepper';
import Paragraph from './Paragraph';
import Video from '../Video';
import { useMedia } from 'react-use';
import PeerConnect from '../../assets/videos/PeerConnect.mov';
import ReportAnIncident from '../../assets/videos/ReportAnIncident.mov';
import ReviewSystem from '../../assets/videos/ReviewSystem.mov';
import SafetyAssistance from '../../assets/videos/SafetyAssistance.mov';
import AnimatedButton from '../AnimatedButton';
import NextCase from '../NextCase';
import ZeroToOneLabsLogo from '../../assets/new-images/ZeroToOneLabs1.png'


function GlobalBridge() {
  const isMobileSize = useMedia("(max-width: 480px)");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const videoRef = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null)
  ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const videoContainerRef = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null)
  ]
  const sectionButtionRef = useRef<HTMLDivElement>(null);
  const observedDivRef = useRef<HTMLDivElement>(null);
  useIntersectionObserver(sectionButtionRef, observedDivRef);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ZeroToOneLabs", title: "ZeroToOne Labs" });
  });
  function redirectToBook() {
    window.open('https://drive.google.com/file/d/1EogpyyP9PRsbx4NY6L99R9pyuJMA8Mam/view?usp=sharing', '_blank')
  }
  function redirectToMirobord() {
    window.open('https://miro.com/app/board/uXjVLL6ZP7o=/?share_link_id=543231912932', '_blank')
  }
  return (
    <>
      <Header />
      <section className={`container-data ${styles.blueDiv} ${styles.GB__blueDiv_BC}`}>
        <div className={styles.image_container} data-aos="fade-up">
          <img src={GlobalBridgeHeroImg} alt='Img 1' />
        </div>
        <div>
          <a onClick={() => {
            ReactGA.event({
              category: "TJU",
              action: "click view prototype",
              label: "TJU prototype"
            });
          }} href='https://www.canva.com/design/DAGD21pQ0o4/krZZFQKn1jBvZOe5f3Sc7Q/view?utm_content=DAGD21pQ0o4&utm_campaign=designshare&utm_medium=link&utm_source=editor' className={styles.circle} target='_blank' rel='noreferrer'>
            View<br></br>Prototype
          </a>
        </div>
      </section>
      <section className={`container-data ${styles.vf_main_title} ${styles.vf_section_mt}`} data-aos="fade-up">
        Global Bridge
      </section>
      <section className={`container-data ${styles.stepper_container_main}`}>
        <Stepper name="Role" desc="Product Designer" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.vf_stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.vf_stepper_desc_width}`} />
        <Stepper name="What I Did" desc="User Research, User Interviews, Business Model, Data analysis , User Flow, Usability Testing." containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.vf_stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.vf_stepper_desc_width}`} />
        <Stepper name="Time line" desc="6 Months" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.vf_stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.vf_stepper_desc_width}`} />
      </section>
      <section className={`container-data ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Goal</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            To establish a trusted, student-driven support network at Thomas Jefferson University that empowers international students with reliable travel insights, peer collaboration, and safety driven decision making tools ensuring they feel confident and secure when traveling solo in Philadelphia.
          </div>
        </div>

        <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
          <span className={styles.stepper_name}>Tools</span>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <span className={styles.vf_tool}>Usability Testing</span>
            <span className={styles.vf_tool}>Figjam</span>
            <span className={styles.vf_tool}>User Interviews</span>
            <span className={styles.vf_tool}>Pretotyping</span>
            <span className={styles.vf_tool}>Figma</span>
            <span className={styles.vf_tool}>MiroBoard</span>
            <span className={styles.vf_tool}>Google Analytics</span>
            <span className={styles.vf_tool}>Google Surveys</span>
          </div>
        </div>
      </section>
      <section className={`container-data ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Problem Statement</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <Paragraph mainText='Lack of Safety Insights' text=' - International students struggle to find reliable, real-time safety information, making solo travel risky.' />
            <Paragraph mainText='Limited Peer Support' text=' - There is no structured way to connect with peers for travel guidance, leading to isolation and uncertainty.' />
            <Paragraph mainText='Decision-Making Barriers' text=' - Scattered resources and cultural hesitations prevent students from making informed travel choices.' />
          </div>
        </div>

        <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
          <span className={styles.stepper_name}>Challenge</span>
          <div className={`${styles.vf_tools_container} ${styles.vf_text_desc_mt}`} >
            <span className={styles.vf_challenge_text}>How might we develop a community support network within Thomas Jefferson University by leveraging peer collaborations to assist its international students in making informed and safe decisions when traveling solo in Philadelphia</span>
          </div>
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <span className={styles.stepper_name}>Solution and Impact</span>
      </section>
      <section className={`container-data  ${styles.vf_about_vf_container_takeways} ${styles.vf_section_mt_1}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <b>Global Bridge</b> is a safety and travel support app for international students at Thomas Jefferson University. It provides real-time safety alerts, location updates, peer connections, and community-driven safety reviews, ensuring safer and more informed travel
          </div>
        </div>
        <div className={`${styles.vf_right} ${styles.vf_logo_image_container_main}`} data-aos="fade-up">
          <div className={`${styles.vf_logo_image_container}`} >
            <img src={GBBackDrop} alt='Img 1' />
          </div>
        </div>
      </section>
      <section className={`container-data-small ${styles.GB_video_conatiner} ${styles.design_outcome_caontainer} ${styles.vf_section_mt}`}>
        <div className={`container-data-small ${styles.section5ContainerClass}`}>
          <div ref={videoContainerRef[1]} className={styles.section5ContentContainerOne} data-aos="fade-up">
            <Video src={SafetyAssistance} width={isMobileSize ? "100%" : "300"} height={isMobileSize ? "100%" : "399"} videoRef={videoRef[1]} />

            <div className={styles.section5TextContainer} >
              <span className={styles.section5DesignOutcomesTitle}>Design outcomes</span>

              <span className={styles.section5HairomaTitle}>Safety Assistancee</span>
              <div className={styles.section5TextContent}>
                <div className={styles.section5Text}>
                  Students can quickly alert friends and nearby app users if they feel unsafe, with real-time location updates ensuring immediate support and enhanced safety awareness.
                </div >
              </div>
            </div>
          </div>
          <div ref={videoContainerRef[2]} className={`${styles.section5ContentContainerOne} ${styles.section5ContentContainer}`} data-aos="fade-up">
            <Video src={ReviewSystem} width={isMobileSize ? "100%" : "600"} height={isMobileSize ? "100%" : "399"} videoRef={videoRef[2]} />
            <div className={styles.section5TextContainer}>
              <span className={styles.section5ChooseScentTitle}>Review system</span>
              <div className={styles.section5TextContent}>
                <div className={styles.section5Text}>
                  Students can share and review local places based on safety, helping others make informed decisions and choose safer locations.
                </div >
              </div>
            </div>
          </div>
          <div ref={videoContainerRef[3]} className={`${styles.section5ContentContainerOne} ${styles.section5ContentContainer}`} data-aos="fade-up">
            <Video src={PeerConnect} width={isMobileSize ? "100%" : "600"} height={isMobileSize ? "100%" : "399"} videoRef={videoRef[3]} />
            <div className={styles.section5TextContainer}>
              <span className={styles.section5CheckoutTitle}>peer connect</span>
              <div className={styles.section5TextContent}>
                <div className={styles.section5Text}>
                  Students can connect with peers who share similar interests, fostering a supportive community and encouraging collaboration among international students.
                </div >
              </div>
            </div>
          </div>
          <div ref={videoContainerRef[3]} className={`${styles.section5ContentContainerOne} ${styles.section5ContentContainer}`} data-aos="fade-up">
            <Video src={ReportAnIncident} width={isMobileSize ? "100%" : "600"} height={isMobileSize ? "100%" : "399"} videoRef={videoRef[3]} />
            <div className={styles.section5TextContainer}>
              <span className={styles.section5CheckoutTitle}>report an incident</span>
              <div className={styles.section5TextContent}>
                <div className={styles.section5Text}>
                  Students can report incidents, ensuring prompt support and raising safety awareness within the community. This feature helps maintain a safer environment by keeping everyone informed and prepared.
                </div >
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_about_vf_container} ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Research Objectives</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <Paragraph mainText='Understand Safety Concerns ' text=' - Identify the key challenges and risks international students face when traveling solo in Philadelphia.' />
            <Paragraph mainText='Analyze Decision-Making Barriers ' text=' - Explore the factors preventing students from making informed travel choices, such as lack of safety insights, peer support, or cultural hesitations.' />
            <Paragraph mainText='Evaluate Existing Support Systems ' text=' - Assess the current safety resources available to students and uncover gaps in accessibility, reliability, and effectiveness.' />
          </div>
        </div>

        <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
          <span className={styles.stepper_name} data-aos="fade-up">Research Goals</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            <Paragraph mainText='Comprehensive Understanding ' text='- Investigate the safety concerns, travel behaviors, and decision-making challenges of international students when navigating Philadelphia alone.' />
            <Paragraph mainText='Communication Channels & Resources Analysis ' text=' - Examine how students currently access safety information, the effectiveness of university-provided resources, and gaps in real-time support.' />
            <Paragraph mainText='Existing Solutions & Their Limitations ' text=' - Analyze current safety apps, university support systems, and informal peer networks to identify their strengths, weaknesses, and areas for improvement.' />
          </div>
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={`container-data-small ${styles.gb_logo_image_container_bg}`}>
          <div className={styles.vf_about_vf_container}>
            <div className={styles.vf_left} data-aos="fade-up">
              <span className={styles.stepper_name}>Secondary research</span>
              <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
                <a href="https://newsroom.adt.com/safe-stories/research-finds-82-percent-american-college-students-are" target='_blank' rel="noreferrer"><div className={styles.vf_link_text}>
                  🔗 American College Safety Concerns
                </div></a>
                <a href="https://internationalcenter.ucla.edu/file/e81ea872-fd5a-484f-8798-e60aabc38b4d" target='_blank' rel="noreferrer"><div className={styles.vf_link_text}>
                  🔗International Students Perceptions about Safety
                </div></a>
                <a href="https://6abc.com/feature/philadelphia-safety-tracker-crime-stats-how-safe-is-my-neighborhood-zip-code-statistics/12455998/" target='_blank' rel="noreferrer"><div className={styles.vf_link_text}>
                  🔗Philadelphia’s Safety Crime Stats

                </div></a>
              </div>
            </div>
            <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
              <div className={`${styles.gb_logo_image_container}`} >
                <img src={GBHMV} alt='Img 1' />
              </div>
            </div>
          </div>
          <div className={styles.gb_data_container}>
            <span className={styles.stepper_name} data-aos="fade-up">Participant Recruiting criteria</span>
            <div className={`${styles.vf_right} ${styles.gb_mv_mr} ${styles.gb_student_status_conatiner_title}`} data-aos="fade-up">
              For recruiting participants, you want to ensure diversity while focusing on students who can provide meaningful insights into safety, travel behavior, and peer support needs. Here are the top 3-4 participant recruitment parameters:
            </div>
            <div className={styles.gb_student_status_container}>
              <div className={styles.gb_student_status_conatiner_title}>
                International Student Status
              </div>
              <Paragraph mainText='' text='Newly arrived students (0-6 months in Philadelphia)' />
              <Paragraph mainText='' text='Students who have lived in Philadelphia for over a year' />
              <Paragraph mainText='' text='This ensures perspectives from both newcomers (who may feel more vulnerable) and experienced students (who have developed strategies).' />
              <div className={`${styles.gb_student_status_conatiner_title} ${styles.gb_mv_mr}`}>
                Travel Behavior
              </div>
              <Paragraph mainText='' text='Students who frequently travel solo within Philadelphia' />
              <Paragraph mainText='' text='Students who prefer to travel with others or avoid certain areas' />
              <Paragraph mainText='' text='Helps compare different safety concerns and decision-making patterns.' />
              <div className={`${styles.gb_student_status_conatiner_title} ${styles.gb_mv_mr}`}>
                Safety Awareness & Incident Experience
              </div>
              <Paragraph mainText='' text='Students who have faced or witnessed safety-related incidents' />
              <Paragraph mainText='' text='Students who have never encountered a safety issue but actively take precautions' />
              <Paragraph mainText='' text='Balances perspectives between those with direct experiences and those relying on preventive measures.' />
              <div className={`${styles.gb_student_status_conatiner_title} ${styles.gb_mv_mr}`}>
                Use of Safety Resources & Peer Networks
              </div>
              <Paragraph mainText='' text='Students who actively use university-provided safety resources (e.g., campus escorts, safety apps, community groups)' />
              <Paragraph mainText='' text='Students who are unaware of or do not use any formal safety tools' />
              <Paragraph mainText='' text='Identifies gaps in existing systems and potential adoption barriers for new solutions.' />
            </div>
          </div>
        </div>
      </section >
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Debreifs</span>
          <div className={`${styles.vf_text_desc} ${styles.vf_text_desc_mt}`}>
            After interviewing 12 students, here is a debrief of one of the participants.
          </div>
        </div>
        <div className={`${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
          <img src={GBYathinReddy} alt='Img 1' />
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>style guide</span>
        </div>
        <div className={`${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
          <img src={GBStyleGuide} alt='Img 1' />
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>UI Screens</span>
        </div>
        <div className={`container-data-small ${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
          <img src={GBUIScreens} alt='Img 1' />
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name}>Siri designs</span>
        </div>
        <div className={`container-data-small ${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
          <img src={GBSiriDesigns} alt='Img 1' />
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <span className={styles.stepper_name} >iWatch designs</span>
        </div>
        <div className={`container-data-small ${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
          <img src={GBiWatchDesigns} alt='Img 1' />
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <span className={styles.stepper_name} data-aos="fade-up">Usability testing</span>
      </section>
      <section className={`container-data-small  ${styles.vf_about_vf_container_takeways} ${styles.vf_section_mt_1}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <div className={styles.gb_text_content}>I built this solution based on research insights gathered through interviews and Google Form surveys. To evaluate its effectiveness, I conducted usability sessions with international students, ensuring a diverse range of experiences. Participants were recruited based on their time spent in Philadelphia:</div>
          <div className={`${styles.vf_text_desc}`}>
            <Paragraph mainText='' text='0-6 months: Newcomers who only know their university and a few nearby places.' />
            <Paragraph mainText='' text="6-12 months: Students with limited knowledge of Philadelphia beyond key areas." />
            <Paragraph mainText='' text="1-2 years: Those familiar with the city and its neighborhoods." />
            <Paragraph mainText='' text="2+ years: Graduates living in different areas, with an in-depth understanding of Philadelphia." />
          </div>
          <div className={styles.vf_debreifs_mt}>
            <AnimatedButton onClick={redirectToMirobord} name='View Miroboard' id='mira_board_11' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class miraboard_height_width_style view_mira_board_border view_mira_board_name_style' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle miraboard_height_width_style' />
          </div>
        </div>
        <div className={`${styles.vf_right}`} data-aos="fade-up">
          <div className={`${styles.gb_logo_image_container}`} >
            <img src={GBSaiTesting} alt='Img 1' />
          </div>
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <span className={styles.stepper_name} data-aos="fade-up">testing Insights</span>
      </section>
      <section className={`container-data-small  ${styles.vf_about_vf_container_takeways} ${styles.vf_section_mt_1}`}>
        <div className={styles.vf_left} data-aos="fade-up">
          <div className={styles.gb_text_content}>Based on the insights received from the students, I categorized them using a Frequency-Severity Matrix to prioritize key issues effectively.</div>
          <div className={`${styles.vf_text_desc}`}>
            <Paragraph mainText='' text='Frequency refers to how often a particular concern or challenge was mentioned across the interviews. Higher frequency indicates a widespread issue affecting many students.' />
            <Paragraph mainText='' text="Severity measures the impact of the issue on students' safety, decision-making, or overall experience. High-severity issues pose significant risks or challenges that require immediate attention." />
            <div className={styles.gb_text_content}>By plotting the insights on this matrix, I was able to identify patterns and prioritize solutions based on their urgency and prevalence. </div>
          </div>
        </div>
        <div className={`${styles.vf_right}`} data-aos="fade-up">
          <div className={`${styles.gb_logo_image_container}`} >
            <img src={GBSevMatrix} alt='Img 1' />
          </div>
        </div>
      </section>
      <section className={`container-data-small ${styles.vf_section_mt}`}>
        <div className={`container-data-small ${styles.gb_logo_image_container_bg}`}>
          <span className={styles.stepper_name} data-aos="fade-up">testing Insights</span>
          <div className={`${styles.gb_logo_image_pd} ${styles.vf_about_vf_container}`}>
            <div className={styles.vf_left} data-aos="fade-up">
              <div className={`${styles.vf_logo_image_container_new} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
                <img src={GBPhoneface1} alt='Img 1' />
              </div>
            </div>

            <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
              <div className={`${styles.vf_logo_image_container_new} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
                <img src={GBPhoneface2} alt='Img 1' />
              </div>
            </div>
          </div>
          <div className={`${styles.vf_about_vf_container}`}>
            <div className={styles.vf_left} data-aos="fade-up">
              <div className={`${styles.gb_testing_insight_font_uc} ${styles.gb_testing_insight_font}`}><b>Problem 1:</b></div>
              <div className={`${styles.gb_testing_insight_font} ${styles.gb_testing_insight_font_mt}`}>Users were confused by the 'Add Location Sharing' feature, assuming they had to enter their friend's unique campus key, which was hard to remember. They expected a simpler format, like using their friend's first and last name instead.</div>
            </div>

            <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
              <div className={`${styles.gb_testing_insight_font_uc} ${styles.gb_testing_insight_font}`}><b>problem 2:</b></div>
              <div className={`${styles.gb_testing_insight_font} ${styles.gb_testing_insight_font_mt}`}>Users struggled to find where to update their safety preferences for Peer Connect, often confusing 'Settings' with 'Edit Profile', leading to uncertainty about where to make changes.</div>
            </div>
          </div>

          <div className={`${styles.gb_logo_image_pd} ${styles.vf_about_vf_container}`}>
            <div className={styles.vf_left} data-aos="fade-up">
              <div className={`${styles.vf_logo_image_container_new} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
                <img src={GBPhoneface3} alt='Img 1' />
              </div>
            </div>

            <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
              <div className={`${styles.vf_logo_image_container_new} ${styles.vf_debreifs_mt}`} data-aos="fade-up">
                <img src={GBPhoneface4} alt='Img 1' />
              </div>
            </div>
          </div>
          <div className={`${styles.vf_about_vf_container}`}>
            <div className={styles.vf_left} data-aos="fade-up">
              <div className={`${styles.gb_testing_insight_font_uc} ${styles.gb_testing_insight_font}`}><b>solution 1:</b></div>
              <div className={`${styles.gb_testing_insight_font} ${styles.gb_testing_insight_font_mt}`}>I've improved the UI by adding an info card with clear instructions, allowing users to add friends using either their campus key or Firstname.Lastname, making the process more intuitive and user-friendly.</div>
            </div>

            <div className={`${styles.vf_right} ${styles.gb_mv_mr}`} data-aos="fade-up">
              <div className={`${styles.gb_testing_insight_font_uc} ${styles.gb_testing_insight_font}`}><b>Solution 2:</b></div>
              <div className={`${styles.gb_testing_insight_font} ${styles.gb_testing_insight_font_mt}`}>I merged 'Edit Profile' into 'Settings' under 'Safety Preferences' to eliminate confusion and make it easier for users to update their preferences in one place.</div>
            </div>
          </div>
        </div>
      </section >

      <section className={`container-data-small  ${styles.vf_section_mt}`}>
        <div className={`${styles.vf_about_vf_container_takeways}`}>
          <div className={`${styles.vf_left} ${styles.gb_mv_mr}`} data-aos="fade-up">
            <span className={styles.stepper_name}>Global Bridge x Jefferson University</span>
            <div className={`${styles.vf_text_desc_mt} ${styles.gb_text_content}`}>
              Global Bridge is going live! Thomas Jefferson University is integrating it into its ecosystem, fostering a safer and more connected community for international students. By providing real-time safety updates, peer support, and seamless location sharing, Global Bridge empowers students to navigate the city with confidence. This integration marks a significant step toward enhancing student well-being, ensuring they feel supported and informed while traveling solo.
            </div>
          </div>
          <div className={`${styles.vf_right}`} data-aos="fade-up">
            <div className={`${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} >
              <img src={GBGlobalBridgeJeffersonUniversity} alt='Img 1' />
            </div>
          </div>
        </div>
        <div className={`${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} >
          <img src={GBDaiwatsPortfolio2025} alt='Img 1' />
        </div>
      </section>
      <section className={`container-data-small  ${styles.vf_section_mt}`}>
        <span className={styles.stepper_name} data-aos="fade-up">From Research to Reality: The Thesis Book Behind Global Bridge</span>
        <div className={`${styles.vf_about_vf_container_takeways}`}>
          <div className={styles.vf_left} data-aos="fade-up">
            <div className={`${styles.vf_text_desc_mt} ${styles.gb_text_content}`}>
              The Global Bridge Thesis Book is a comprehensive documentation of the research, strategy, and design process that shaped the app. It includes user interviews, storyboards, a business model, usability testing insights, and key design decisions, all critical in building a solution tailored to the needs of international students at Thomas Jefferson University. This book not only highlights the challenges faced by students but also showcases the iterative UX process that transformed insights into a functional, real-world product.
            </div>
            <div className={styles.vf_debreifs_mt}>
              <AnimatedButton onClick={redirectToBook} name='View Book' id='mira_board_11' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='about_common_class viewbook_height_width_style view_mira_board_border view_mira_board_name_style' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle viewbook_height_width_style' />
            </div>
          </div>
          <div className={`${styles.vf_right}`} data-aos="fade-up">
            <div className={`${styles.gb_logo_image_container} ${styles.vf_debreifs_mt}`} >
              <img src={GBBookCover1} alt='Img 1' />
            </div>
          </div>
        </div>
      </section>
      <section className={`${styles.vf_section_mt}`}>
        <span className={`container-data-small ${styles.stepper_name}`} data-aos="fade-up">next steps</span>
        <div className={styles.vf_takeWays_container_main}>
          <div className={`container-data-small ${styles.vf_about_vf_container_takeways} ${styles.vf_takeWays_container}`}>
            <div className={`${styles.vf_left} ${styles.vf_section_mt_1}`} data-aos="fade-up">
              <Paragraph mainText='Expand User Base' text=': Increase awareness among international students at TJU and other universities through targeted campaigns, boosting app downloads and active engagement.' />
              <Paragraph mainText='Add New Features' text=": Continuously enhance the app with features like AI-driven recommendations, public transport integration, and cultural event suggestions to keep users engaged." />
              <Paragraph mainText='Marketing' text=": Promote the app via social media, student organizations, and campus events, emphasizing its safety and community-building benefits." />
            </div>
            <div className={`${styles.vf_right} ${styles.vf_logo_image_container_main}`} data-aos="fade-up">
              <div className={`${styles.vf_logo_image_container}`} >
                <img src={GBBottomLogo} alt='Img 1' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <NextCase sectionButtionRef={sectionButtionRef} caseName='TATA Fleet Edge' redirectUrl="/ZeroToOneLabs" imageUrl={ZeroToOneLabsLogo} />

    </>
  )
}

export default GlobalBridge