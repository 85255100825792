import React, { useEffect, useRef } from 'react'
import Header from '../Header'
import styles from './index.module.css';
import DesignCozHeroImg from '../../assets/new-images/DesignCozHeroImg.png';
import Stepper from '../Stepper';
import Footer from '../Footer';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import ReactGA from "react-ga4";


function DesignCoz() {
  const sectionButtionRef = useRef<HTMLDivElement>(null);
  const observedDivRef = useRef<HTMLDivElement>(null);
  useIntersectionObserver(sectionButtionRef, observedDivRef);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "Design Coz", title: "Design Coz" });
  });
  return (
    <>
      <Header />
      <section className={`container-data ${styles.blueDiv} ${styles.DC__blueDiv_BC}`}>
        <div className={styles.image_container} data-aos="fade-up">
          <img src={DesignCozHeroImg} alt='Img 1' />
        </div>
        {/* <div>
          <a onClick={() => {
            ReactGA.event({
              category: "TJU",
              action: "click view prototype",
              label: "TJU prototype"
            });
          }} href='#/' className={styles.circle} target='_blank' rel='noreferrer'>
            Website<br></br>Link
          </a>
        </div> */}
      </section>
      <section className={`container-data ${styles.stepper_container_main}`}>
        <Stepper name="Role" desc="UI / UX Designer" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
        <Stepper name="What I Did" desc="UX Design, UI Design, Design System Interaction." containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
        <Stepper name="Time line" desc="6 Months" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
        <Stepper name="Company" desc="DesignCoz" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
      </section>
      <section className={`${styles.exp_container_main_1} ${styles.exp_container_main}`}>
        <div className={`container-data  ${styles.exp_container} ${styles.exp_container_padding1}`}>
          <div data-aos="fade-up" className={styles.stepper_name}>
            Reflection
          </div>
          <div data-aos="fade-up" className={styles.exp}>
            The Experience
          </div>
          <div data-aos="fade-up" className={styles.exp_desc}>
            I worked for 6 months as a UX/UI Designer at DesignCoz, focusing primarily on UI design. During my tenure, I worked exclusively on the WIFY brand, designing three modules—Technician, Supervisor, and Brand—for both mobile and web platforms. I also built design systems to ensure consistency and scalability across the project. It was my first job during Covid, which meant my entire work experience was remote. DesignCoz not only helped me grow professionally but also encouraged me to reflect on my life journey and how I arrived at this point. This reflection shaped my work ethic, reinforcing why I design and the path that has led me to where I am today. Due to NDA restrictions, I am unable to showcase the actual designs, but here is a glimpse of the work, with the brand name changed and minor design alterations.
          </div>
        </div>
      </section>
      {/* <NextCase sectionButtionRef={sectionButtionRef} caseName='Calorie Wise' redirectUrl="/calorie-wise" imageUrl={CalorieWiseNextCase} /> */}
      <Footer />
    </>
  )
}

export default DesignCoz