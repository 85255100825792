import React, { useEffect, useRef } from 'react'
import Header from '../Header'
import Stepper from '../Stepper'
import styles from './index.module.css';
import ZeroToOneLabsImg from '../../assets/new-images/ZeroToOneLabs1.png';
import MoodboardImg1 from '../../assets/new-images/Moodboard_1.png';
import MoodboardImg2 from '../../assets/new-images/Moodboard_2.png';
import MoodboardImg3 from '../../assets/new-images/Moodboard_3.png';
import MoodboardImg4 from '../../assets/new-images/Moodboard_4.png';
import MoodboardImg5 from '../../assets/new-images/Moodboard_5.png';
import Footer from '../Footer';
import Video from '../Video';
import TataFleetEdge from '../../assets/videos/TataFleetEdge.mov';
import NextCase from '../NextCase';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import DesignCozLogo from '../../assets/new-images/DesignCozNextCase.png';
import ReactGA from "react-ga4";

function ZeroToOneLabs() {
  const videoRef = React.createRef<HTMLVideoElement>();
  const sectionButtionRef = useRef<HTMLDivElement>(null);
  const observedDivRef = useRef<HTMLDivElement>(null);
  useIntersectionObserver(sectionButtionRef, observedDivRef);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ZeroToOneLabs", title: "ZeroToOne Labs" });
  });

  return (
    <>
      <Header />
      <section className={`container-data ${styles.blueDiv} ${styles.ZTO__blueDiv_BC}`}>
        <div className={styles.image_container}>
          <img src={ZeroToOneLabsImg} alt='Img 1' />
        </div>
      </section>
      <section className={`container-data ${styles.stepper_container_main}`}>
        <Stepper name="Role" desc="UI / UX Designer" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
        <Stepper name="What I Did" desc="UX Design, UI Design, Design System Interaction." containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
        <Stepper name="Time line" desc="4 Months" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
        <Stepper name="Company" desc="ZeroToOne Labs" containerClass={styles.stepper_container} nameClass={styles.stepper_name} lineClass={`${styles.stepper_line} ${styles.stepper_line_width}`} descClass={`${styles.stepper_desc} ${styles.stepper_desc_width}`} />
      </section>
      <section className={`container-data ${styles.zto_goal_obj_container}`}>
        <div className={styles.zto_left_container}>
          <div data-aos="fade-up" className={styles.stepper_name}>
            GOAL
          </div>
          <div data-aos="fade-up" className={styles.zto_goal_obj_desc}>
            To create a user-friendly and efficient fleet management solution that empowers businesses to monitor and maintain their vehicles, improve operational efficiency, and ensure compliance with safety standards.
          </div>
        </div>
        <div className={styles.zto_right_container}>
          <div data-aos="fade-up" className={styles.stepper_name}>
            Objective
          </div>
          <div data-aos="fade-up" className={styles.zto_goal_obj_desc}>
            Design software for TATA’s Fleet Edge to enable real-time vehicle tracking, monitor vehicle health and violations, schedule services, manage maintenance, and provide insights into driver behavior and trip efficiency.
          </div>
        </div>
      </section>
      <section className={`container-data ${styles.zto_moodboard}`}>
        <div data-aos="fade-up" className={styles.stepper_name}>
          Moodboard
        </div>
      </section>
      <section className={`container-data-small ${styles.zto_moodboard_img_container}`}>
        <div data-aos="fade-up" className={styles.zto_img_container_main}>
          <div className={styles.zto_img_container_data}>
            <img data-aos="fade-up" src={MoodboardImg1} alt='Img 1' />
            <img data-aos="fade-up" src={MoodboardImg2} alt='Img 1' />
            <img data-aos="fade-up" src={MoodboardImg3} alt='Img 1' />
          </div>
          <div className={styles.zto_img_container_data}>
            <img data-aos="fade-up" src={MoodboardImg4} alt='Img 1' />
            <img data-aos="fade-up" src={MoodboardImg5} alt='Img 1' />
          </div>
        </div>
      </section>
      <section data-aos="fade-up" className={`${styles.exp_container_main_1}`}>
        <div className={`container-data  ${styles.exp_container}`}>
          <div data-aos="fade-up" className={styles.stepper_name}>
            Reflection
          </div>
          <div data-aos="fade-up" className={styles.exp}>
            The Experience
          </div>
          <div data-aos="fade-up" className={styles.exp_desc}>
            <div>
              During my 6 months as a UI/UX Designer at ZeroToOne Labs, I worked exclusively on the TATA brand, focusing on creating user interfaces for both mobile and web platforms. My work spanned multiple modules, allowing me to tackle diverse design challenges and refine my skills in UI and Interaction Design. This role also provided valuable opportunities for professional growth.
            </div>
            <br></br>
            <div>
              Due to NDA restrictions, I am unable to showcase the original designs. To maintain confidentiality, the brand name has been changed, and the designs have been modified. To offer insight into the project, I’ve presented one user flow that illustrates my design process while adhering to confidentiality guidelines.
            </div>
          </div>
        </div>
      </section>

      <section className={`container-data ${styles.margin_top}`}>
        <div data-aos="fade-up" className={styles.stepper_name}>
          Adding driver flow - tata fleet edge
        </div>
      </section>

      <section className={`${styles.exp_container_main}`}>
        <div className={`container-data-small  ${styles.exp_container} ${styles.exp_container_padding1}`}>
          <div data-aos="fade-up" className={styles.zto_adf_container}>
            <Video src={TataFleetEdge} width="100%" height="100%" videoRef={videoRef} />
          </div>
        </div>
      </section>
      <NextCase sectionButtionRef={sectionButtionRef} caseName='DesignCoz' redirectUrl="/DesignCoz" imageUrl={DesignCozLogo} />
      <Footer />
    </>
  )
}

export default ZeroToOneLabs