import React from 'react'
import Header from '../Header'
import styles from './index.module.css'
import GetInTouch from '../GetInTouch'
import AnimatedButton from '../AnimatedButton'
import { Link } from 'react-router-dom'

function NotFond() {
  return (
    <>
      {/* <Loader /> */}
      <Header />
      <div className={styles.notfound_container}>
        <div className={styles.notfound_title}>
          <div >
            Error 404
          </div>
          <div>
            Are you lost?
          </div>
        </div>
        <Link to='/'>
          <GetInTouch name="Back to home" id="footer-element" borderClass={styles.not_found_back_to_home_border_color} />
        </Link>
        <div className={styles.not_found_container}>
          <AnimatedButton name='daiwat.work@gmail.com' id='footer_contact' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='not_found_common_class not_found_contact_info_btn_border not_found_btn_font_style footer_btn_height_width_style' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle footer_btn_height_width_style' />
          <AnimatedButton name='+215-930-3964' id='foote_email' mouseEnterTop="130%" mouseEnterToScale={5} linkClassName='not_found_common_class not_found_contact_info_btn_border not_found_btn_font_style footer_contact_info_btn_number' circleClassName='about_common_circle_class footer_contact_info_btn_email_circle footer_contact_info_btn_number' />
        </div>
      </div>
    </>
  )
}

export default NotFond