import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import RootLayout from '../RootLayout';
import Home from '../Home';
import About from '../About';
import CalorieWise from '../Projects/CalorieWise';
import Tju from '../Projects/TJU';
import HairFood from '../Projects/HairFood';
import Work from '../Projects/Work';
import NotFond from '../NotFound';
import DesignCoz from '../Experience/DesignCoz';
import ZeroToOneLabs from '../Experience/ZeroToOneLabs';
import VerdeFinance from '../Experience/VerdeFinance';
import GlobalBridge from '../Experience/GlobalBridge';

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />}></Route>
      <Route path='/work' element={<Work />}></Route>
      <Route path='/about' element={<About />}></Route>
      <Route path='/calorie-wise' element={<CalorieWise />}></Route>
      <Route path='/tju' element={<Tju />}></Route>
      <Route path='/hair-food' element={<HairFood />}></Route>
      <Route path='/DesignCoz' element={<DesignCoz />}></Route>
      <Route path='/ZeroToOneLabs' element={<ZeroToOneLabs />}></Route>
      <Route path='/VerdeFinance' element={<VerdeFinance />}></Route>
      <Route path='/GlobalBridge' element={<GlobalBridge />}></Route>
      <Route path='*' element={<NotFond />}></Route>
    </Route>
  )
)

export default Router