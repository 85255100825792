import React, { useEffect } from 'react'
import styles from './index.module.css'
import GetInTouch from '../GetInTouch';

import Tools from '../Tools';
import DaiwatThavaiImage from '../../assets/new-images/AboutMe.png';
import DaiwatThavaiImageTablet from '../../assets/new-images/Profile-Photo-Tablet.png';
import Footer from '../Footer';
import gsap from 'gsap';
import Header from '../Header';
import { resumeLink } from '../../utils/constants';
import { useMedia } from 'react-use';
import MyJourney from '../MyJourney';
import ReactGA from "react-ga4";

export default function About() {
  const isMobileSize = useMedia("(max-width: 480px)");
  const isTablet = useMedia("(max-width: 835px)");
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/about", title: "About" });
    let tl = gsap.timeline({ ease: "SlowMo.easeOut" });
    tl.fromTo('#hide', { opacity: 0 }, {
      opacity: 1, duration: 0.5, onComplete: () => {
        tl.to('#create', { y: '0%', duration: 0.5 });
      }
    });
  }, []);
  return (
    <section>
      {/* <Loader /> */}
      <Header />
      <section className={styles.about_container}>
        <div id='hide'>
          <div className={`${styles.about_info} hide_overflow`}>
            <div><span id='create'>Designer based in</span></div>
            <div><span id='create'>USA</span></div>
          </div>
        </div>
        <a href={resumeLink} target='_blank' rel="noreferrer" onClick={() => {
          ReactGA.event({
            category: 'About',
            action: 'Click on view resume',
            label: 'Resume Download',
          });
        }}>
          <GetInTouch nameClassName={styles.nameClassName} linkClassName={styles.linkClassName} showParallex={false} name='View Resume' borderClass={styles.border_download_resume} id="get_in_touch" />
        </a>
        {/* about me */}
        <div className={`container-data-small ${styles.about_me_container}`}>
          {/* <div className={styles.about_me_container_left}>
            <img src={rightArrowSvg} alt="right arrow" />
          </div> */}
          <div className={styles.about_me_container_right}>
            <div data-aos="fade-up" className={styles.right}>
              <div data-aos="fade-up" className={styles.right_about_me}>
                <div data-aos="fade-up" className={styles.about_me_title} >
                  About
                </div>
                <div data-aos="fade-up" className={styles.itemText}>Present</div>

                <div data-aos="fade-up" className={styles.about_me_desc}>
                  I'm Daiwat Thavai, a User Experience and Interaction Design graduate from Thomas Jefferson University, with a Bachelor's in Information Technology from Vidyalankar School of Information Technology, India. Currently learning Front-End Development and constantly pushing myself to grow creatively in new ways, I’m passionate about crafting user-centered designs that combine creativity with a data-driven approach. I’m seeking a full-time role where I can leverage my skills to create impactful digital experiences.
                </div>
                <div data-aos="fade-up" className={styles.itemText}>Growth</div>

                <div data-aos="fade-up" className={styles.about_me_desc}>
                  Growing up, my passions were digital design, fashion, and soccer, all of which shaped my skills and mindset. Design taught me to create visually engaging experiences; fashion sharpened my eye for color, texture, and aesthetics; and soccer instilled leadership, teamwork, and discipline. This combination fueled my curiosity and drive for growth.
                </div>
              </div>
            </div>
            <div data-aos="fade-up" className={styles.left}>
              <div data-aos="fade-up" className={styles.left_about_me}>
                {/* workout */}
                <img src={isTablet ? DaiwatThavaiImageTablet : DaiwatThavaiImage} alt='Daiwat Thavai'></img>
                <iframe style={{ borderRadius: '12px', marginLeft: isMobileSize ? "0" : '12px', marginTop: isMobileSize ? "20px" : '0' }} src="https://open.spotify.com/embed/playlist/1yJghRj4N9XHF6cT09Dlho?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title="spotify-playlist-1"></iframe>
              </div>
              <div data-aos="fade-up" className={styles.left_about_me} style={{ marginTop: isMobileSize ? "10px" : '0' }}>
                {/* Vibin */}
                <iframe style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/playlist/0yLbxoCkqcTthjdhXzpFDP?utm_source=generator" width="100%" height="352" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title="spotify-playlist-2"></iframe>
                {/* flow */}
                <iframe style={{ borderRadius: '12px', marginLeft: isMobileSize ? "0" : '12px', marginTop: isMobileSize ? "20px" : '0' }} src="https://open.spotify.com/embed/playlist/3ZoVribEaJ9oR2aEjngXYK?utm_source=generator" width="100%" height="352" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title="spotify-playlist-3"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* I can help you with.. */}
      <div className={`container-data-small ${styles.help_conatiner}`}>
        <div data-aos="fade-up" className={styles.itemText2}>I can help you with..</div>
        <div className={styles.help_section_container} data-aos="fade-up">
          <div>
            <div className={styles.help_section_title}>01</div>
            <div className={styles.help_section_type}>Design Strategy</div>
            <div className={styles.help_section_category}>
              <p>Vision & Planning</p>
              <p>User-Focused Approaches</p>
              <p>Market Research</p>
            </div>
          </div>
          <div>
            <div className={styles.help_section_title}>02</div>
            <div className={styles.help_section_type}>User Research</div>
            <div className={styles.help_section_category}>
              <p>Interviews & Surveys</p>
              <p>User Insights</p>
              <p>Research Methods</p>
            </div>
          </div>
          <div>
            <div className={styles.help_section_title}>03</div>
            <div className={styles.help_section_type}>Interactive Design</div>
            <div className={styles.help_section_category}>
              <p>Visual Design</p>
              <p>Design Systems</p>
              <p>Prototyping</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`container-data-small`}>
        <Tools />
      </div>

      <div className={styles.about_project_mind_container_parent}>
        <div className={`container-data-small ${styles.about_project_mind_container}`}>
          <MyJourney />
        </div>
      </div>
      <Footer />
    </section >
  )
}
