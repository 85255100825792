import { Outlet, useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import SideHeader from "./SideHeader";
import useAOS from "../hooks/useAOS";
import useWindowScroll from "../hooks/useWindowScroll";
import Loader from "./Loader";

function RootLayout() {
  const lenisRef = useRef(null);
  const { currentScrollPosition } = useWindowScroll();
  const [isLoader, setIsLoader] = useState(true);
  const location = useLocation();
  const [loaderTimeout, setLoaderTimeout] = useState(4000);
  useEffect(() => {
    setIsLoader(true);
    const timer = setTimeout(() => {
      setIsLoader(false);
    }, loaderTimeout);

    return () => clearTimeout(timer); // Cleanup the timer if location changes quickly
  }, [location.pathname, loaderTimeout]);

  useAOS();

  return (
    <>
      <SideHeader scrollPositionValue={currentScrollPosition} />
      {isLoader && <Loader setLoaderTimeout={setLoaderTimeout} />}
      {!isLoader && (
        <div ref={lenisRef}>
          <Outlet />
        </div>
      )}
    </>
  );
}

export default RootLayout;
