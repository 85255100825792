import React, { useState } from 'react'

function Video({ src, width = "600", height = "399", videoRef }: any) {
  const [controlsState, setControls] = useState<any>(false);
  return (
    <video
      ref={videoRef}
      id={src}
      onMouseEnter={() => setControls(true)}
      onMouseLeave={() => setControls(false)}
      src={src} width={width} height={height}
      controls={controlsState}
      autoPlay
      playsInline
      muted
      loop />
  )
}

export default Video