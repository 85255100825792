import React, { useEffect } from 'react'
import gsap from 'gsap';

function useIntersectionObserver(sectionButtionRef: React.RefObject<HTMLDivElement>, observedDivRef: React.RefObject<HTMLDivElement>) {
  useEffect(() => {
    // Create a new IntersectionObserver instance
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // If the observed div is in the viewport, hide the target div
          if (sectionButtionRef?.current) {
            gsap.to(sectionButtionRef.current, { opacity: 0, duration: 0.5 })
          }
        } else {
          // If the observed div is out of the viewport, show the target div
          if (sectionButtionRef?.current) {
            gsap.to(sectionButtionRef.current, { opacity: 1, duration: 0.5 })
          }
        }
      });
    }, {
      root: null, // Use the viewport as the root
      threshold: 0.1 // Trigger when 10% of the observed element is in view
    });

    // Start observing the observed div
    if (observedDivRef.current) {
      observer.observe(observedDivRef.current);
    }
  }, [observedDivRef, sectionButtionRef]);
}

export default useIntersectionObserver