import style from './index.module.css'
import { NavLink, useLocation } from 'react-router-dom';
import { navLinks } from '../../utils/constants';
import { appName } from '../../utils/constants';
import gsap from 'gsap';
import useAttractHoverEffect from '../../hooks/useAttractHoverEffect';
import MobileMenu from '../../assets/new-images/MobileMenu.png'
import MobileMenuWhite from '../../assets/new-images/MenuIconWhite.png'
import MenuClose from '../../assets/new-images/MenuClose.png'
import { resumeLink } from '../../utils/constants';
import { useMedia } from 'react-use';
import { useEffect, useRef, useState } from 'react';
import ReactGA from "react-ga4";

type MobileHeaderMenuProps = {
  openCloseModal: React.Dispatch<React.SetStateAction<boolean>>
}
function Header() {
  const location = useLocation();
  const isMobileSize = useMedia("(max-width: 480px)");
  console.log("isMobileSize", isMobileSize);
  const { pathname } = location;
  function showHoverActive(link: any) {
    if (link.to !== pathname) {
      const ele = '#dot' + link.name;
      gsap.fromTo(ele, { height: 0, width: 0 }, { height: '6px', width: '6px', duration: 0.5 })
    }
  }

  function hideHoverActive(link: any) {
    if (link.to !== pathname) {
      const ele = '#dot' + link.name;
      gsap.fromTo(ele, { height: '6px', width: '6px' }, { height: 0, width: 0, duration: 0.5 })
    }
  }
  useAttractHoverEffect('#header_logo', 0.2, 0.3);
  useAttractHoverEffect('#nav1', 0.5, 0.4);
  useAttractHoverEffect('#nav2', 0.5, 0.3);
  useAttractHoverEffect('#nav3', 0.5, 0.3);
  const DesktopHeader = () => {
    return (<section>
      <div className={`${style.header} ${style.header_font_style} ${(pathname === "/DesignCoz" || pathname === "/ZeroToOneLabs" || pathname === "/VerdeFinance" || pathname === "/GlobalBridge") ? style.header_font_style_white : style.header_font_style_main} flex jc-space-between`} style={pathname === "/hair-food" ? { background: "#FBF5F1" } : pathname === "/calorie-wise" ? { background: "#F9F9F9" } : pathname === "/tju" ? { background: "#E6EDFB" } : (pathname === "/DesignCoz") ? { background: "#18233D" } : pathname === "/ZeroToOneLabs" ? { background: "#D1153B" } : pathname === "/VerdeFinance" ? { background: "#32A767" } : pathname === "/GlobalBridge" ? { background: "#007AFF" } : {}}>
        <NavLink to='/' id='header_logo' onClick={() => {
          ReactGA.event({
            category: "Header",
            action: `Click on Logo`,
          });
        }}>
          {appName}
        </NavLink>
        <ul className={`flex ${style.nav_items}`}>
          {navLinks.map((link, idx) => {
            return (
              <li id={'nav' + idx} className={style.header_nav_container} key={link.id} onMouseEnter={() => showHoverActive(link)} onMouseLeave={() => hideHoverActive(link)}>
                <NavLink
                  to={link.to}
                  className={style.dot_container}
                  onClick={() => {
                    ReactGA.event({
                      category: "Header",
                      action: `Click on ${link.name}`,
                    });
                  }}
                >
                  {idx === 0 ? '' : link.name}
                  {idx ? <div id={'dot' + link.name} className={pathname === link.to ? `${style.dot_container_common} ${(pathname === "/DesignCoz" || pathname === "/ZeroToOneLabs" || pathname === "/VerdeFinance" || pathname === "/GlobalBridge") ? style.dot_container_common_white : style.dot_container_common_main} ${style.dot_container_opacity}` : `${style.dot_container_common} ${(pathname === "/DesignCoz" || pathname === "/ZeroToOneLabs" || pathname === "/VerdeFinance" || pathname === "/GlobalBridge") ? style.dot_container_common_white : style.dot_container_common_main} ${style.dot_on_hover}`}></div> : ''}
                </NavLink>
              </li>
            );
          })}
          <li id={'nav3'} className={style.header_nav_container} key={4} onMouseEnter={() => showHoverActive({ to: '/resume', name: 'resume' })} onMouseLeave={() => hideHoverActive({ to: '/resume', name: 'resume' })}>
            <a
              href={resumeLink}
              target='_blank'
              className={style.dot_container}
              rel='noreferrer'
              onClick={() => {
                ReactGA.event({
                  category: "Header",
                  action: "Click on resume",
                });
              }}
            >
              Resume
              <div id={'dotresume'} className={pathname === '/resume' ? `${style.dot_container_common} ${style.dot_container_opacity}` : `${style.dot_container_common} ${style.dot_on_hover}`}></div>
            </a>
          </li>
        </ul>
      </div>
    </section>)
  }

  const MobileHeaderMenu = ({ openCloseModal }: MobileHeaderMenuProps) => {
    const mobileHeaderMenuRef = useRef(null);
    const closeModal = () => {
      gsap.fromTo(
        mobileHeaderMenuRef.current,
        { height: '100%', opacity: 1 },
        { height: 0, opacity: 0, duration: 0.5, ease: 'power1.out', onComplete: () => { openCloseModal(false) } }
      );
    }
    useEffect(() => {
      if (mobileHeaderMenuRef.current) {
        gsap.fromTo(
          mobileHeaderMenuRef.current,
          { height: 0, opacity: 0 },
          { height: '100%', opacity: 1, duration: 0.4, ease: 'power1.out' }
        );
      }
    }, []);
    return (
      <div ref={mobileHeaderMenuRef} className={style.mobile_menu_container}>
        <div className={style.mobile_menu_nav}>
          <div className={style.mobile_menu_navigation}>NAVIGATION</div>
          <div className={style.mobile_menu_close}>
            <img className={style.menu_close} src={MenuClose} alt='MenuClose' onClick={() => {
              closeModal();
              ReactGA.event({
                category: "Header",
                action: `Click on Menu Close`,
              });
            }} />
          </div>
        </div>
        <div className={style.container_side_name}>
          {navLinks.map((link, idx) => {
            return (
              <NavLink key={link.to} to={link.to} onClick={() => {
                ReactGA.event({
                  category: "Header",
                  action: `Click on ${link.name}`,
                });
                closeModal();
              }}
                className={style.margin_top}>
                <div className={style.name_item} id={'navLink' + idx}>
                  <div className={pathname === link.to ? style.active : style.inactive}></div>
                  <div className={style.mr_left}>{link.name}</div>
                </div>
              </NavLink>
            )
          })}
          <a
            href={resumeLink}
            target='_blank'
            key={'/resume'} onClick={() => openCloseModal(false)} className={style.margin_top} rel="noreferrer">
            <div className={style.name_item} id={'navLink' + 3}>
              <div className={pathname === '/resume' ? style.active : style.inactive}></div>
              <div className={style.mr_left}>Resume</div>
            </div>
          </a>
        </div>
      </div>
    )
  }
  const MobileHeader = () => {
    const [openMenu, setOpenMenu] = useState(false);
    return (
      <>
        {openMenu && <MobileHeaderMenu openCloseModal={setOpenMenu} />}
        <div className={`${style.header} ${style.header_font_style} ${(pathname === "/DesignCoz" || pathname === "/ZeroToOneLabs" || pathname === "/VerdeFinance" || pathname === "/GlobalBridge") ? style.header_font_style_white : style.header_font_style_main} flex jc-space-between`} style={pathname === "/hair-food" ? { background: "#FBF5F1" } : pathname === "/calorie-wise" ? { background: "#F9F9F9" } : pathname === "/tju" ? { background: "#E6EDFB" } : (pathname === "/DesignCoz") ? { background: "#18233D" } : pathname === "/ZeroToOneLabs" ? { background: "#D1153B" } : pathname === "/VerdeFinance" ? { background: "#32A767" } : pathname === "/GlobalBridge" ? { background: "#007AFF" } : {}}>
          <NavLink to='/' id='header_logo' onClick={() => {
            ReactGA.event({
              category: "Header",
              action: "Click on Logo",
            });
          }}>
            {appName}
          </NavLink>
          <img className={style.menuIcon} src={(isMobileSize && (pathname === "/DesignCoz" || pathname === "/ZeroToOneLabs" || pathname === "/VerdeFinance" || pathname === "/GlobalBridge")) ? MobileMenuWhite : MobileMenu} alt='MobileMenu' onClick={() => { setOpenMenu(true) }} />
        </div>
      </>
    )
  }

  return isMobileSize ? <MobileHeader /> : <DesktopHeader />
}

export default Header