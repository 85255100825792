import React from 'react';
import styles from './index.module.css';

type ParagraphPropsType = { text: string, mainText: string }

const Paragraph = ({ text, mainText }: ParagraphPropsType) => {
  return <div className={styles.vf_paragraph_container}>
    <div className={styles.vf_dot}></div>
    <div>
      <span className={styles.vf_main_text}>{mainText}</span>
      <span className={styles.vf_text}>{text}</span>
    </div>
  </div>
}

export default Paragraph