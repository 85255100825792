import React, { useEffect, useRef } from 'react'
import styles from "./index.module.css";
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { motion } from "motion/react"
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import ReactGA from "react-ga4";

type WorkType = {
  bgColor: string,
  title: string,
  name: string;
  desc: string;
  image: string;
  type: string;
  project: { [key: string]: any }
  logo?: string;
}
type StyleType = {
  [key: string]: React.CSSProperties
}

function Work({ bgColor, title, name, desc, image, type, project, logo }: WorkType) {
  const navigate = useNavigate();
  const isMobileSize = useMedia("(max-width: 480px)");
  const workContainerStyle: React.CSSProperties = {
    backgroundColor: bgColor
  }
  const imageStyle: StyleType = {
    "CalorieWise": {
      width: isMobileSize ? "85%" : "271px",
      // height: "394px",
      height: "auto",
      marginLeft: !isMobileSize ? "70px" : "",
      marginTop: isMobileSize ? "40px" : ""
    },
    "GlobalBridge": {
      width: isMobileSize ? "85%" : "60%",
      // height: "394px",
      height: "auto",
      // marginLeft: !isMobileSize ? "18px" : "",
      marginTop: isMobileSize ? "40px" : ""
    },
    "TJU": {
      width: isMobileSize ? "95%" : "45%",
      // height: "367px",
      height: "auto",
      marginTop: isMobileSize ? "40px" : "",
      marginLeft: isMobileSize ? "-5%" : "0"
    },
    "HairFood": {
      width: isMobileSize ? "95%" : "45%",
      // height: "367px",
      height: "auto",
      marginTop: isMobileSize ? "40px" : "",
      marginLeft: isMobileSize ? "-5%" : "0"
    },
    "ZeroToOneLabs": {
      width: isMobileSize ? "100%" : "45%",
      height: "auto",
      marginTop: isMobileSize ? "40px" : ""
    },
    "DesignCoz": {
      width: isMobileSize ? "85%" : "271px",
      height: "auto",
      marginLeft: !isMobileSize ? "70px" : "",
      marginTop: isMobileSize ? "40px" : ""
    }
  }
  const colorStyle: StyleType = {
    "CalorieWise": {
      color: "#1E1E1E"
    },
    "GlobalBridge": {
      color: "#FFFFFF"
    },
    "TJU": {
      color: "#1E1E1E"
    },
    "HairFood": {
      color: "#1E1E1E"
    },
    "ZeroToOneLabs": {
      color: "#FFFFFF"
    },
    "DesignCoz": {
      color: "#FFFFFF"
    }
  }
  const logoStyle: StyleType = {
    "ZeroToOneLabs": {
      width: isMobileSize ? "17px" : "32px",
      height: isMobileSize ? "56px" : "107px",
    },
    "DesignCoz": {
      width: "165px",
      height: "41px",
    }
  }
  const logoContainerStyle: StyleType = {
    "ZeroToOneLabs": {
      right: isMobileSize ? "20px" : "32px",
    },
    "DesignCoz": {
      right: isMobileSize ? "0" : "32px",
      left: isMobileSize ? "20px" : "",
      top: isMobileSize ? "60px" : "32px"
    }
  }
  const scaleRef = useRef(null);
  useEffect(() => {
    if (isMobileSize) return;
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(
      scaleRef.current,
      { scale: 0.5 },
      {
        scale: 1, // End scale
        ease: 'power1.out',
        scrollTrigger: {
          trigger: scaleRef.current,
          start: 'top 95%', // When the top of the element hits 80% of the viewport
          end: 'top 35%', // When the top of the element hits 50% of the viewport
          scrub: true, // Smooth animation
          // markers: true
        },
      }
    );
  }, [isMobileSize, scaleRef]);
  const redirectTo = (item: any) => {
    ReactGA.event({
      category: "Work",
      action: `Click on ${item.name}`,
    });
    if (item.isExternal) {
      window.open(item.URL);
    } else {
      navigate(item.URL)
    }
  }
  return (
    <motion.div
      onClick={() => redirectTo(project)}
      className={styles.work_container} style={workContainerStyle} ref={scaleRef}>
      {logo && <div className={styles.work_logo_container} style={logoContainerStyle[type]}><img src={logo} alt='logo' style={logoStyle[type]}></img></div>}
      <div className={styles.work_title} style={colorStyle[type]}>
        {title}
      </div>
      <div className={styles.work_desc_conatiner}>
        <img src={image} alt='' style={imageStyle[type]}></img>
        <div className={styles.work_right_section}>
          <div className={styles.work_name} style={colorStyle[type]}>
            {name}
          </div>
          <div className={styles.work_desc} style={colorStyle[type]}>
            {desc}
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Work