import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import styles from './index.module.css'
import './preloader.css'
import { useLocation } from 'react-router-dom';
const pageURLs = [
  {
    path: '/',
    name: 'Home'
  },
  {
    path: '/work',
    name: 'Work'
  },
  {
    path: '/about',
    name: 'About'
  },
  {
    path: '/calorie-wise',
    name: 'Calorie Wise'
  },
  {
    path: '/tju',
    name: 'TJU Course Reg'
  },
  {
    path: '/hair-food',
    name: 'Hair Food'
  },
  {
    path: '/global-bridge',
    name: 'Global Bridge'
  },
  {
    path: '/DesignCoz',
    name: 'DesignCoz'
  },
  {
    path: '/ZeroToOneLabs',
    name: 'ZeroToOne Labs'
  },
  {
    path: '/VerdeFinance',
    name: 'Verde Finance'
  },
  {
    path: '/GlobalBridge',
    name: 'Global Bridge'
  }
];

const greetings = [
  "Hi!",
  "Holá!",
  "Bonjour!",
  "Hallo!",
  "Nǐ hǎo!",
  "Ciao!",
  "नमस्ते!",
];
type PreloaderType = {
  isFirstLoad: boolean
  currentPageName: string | undefined
}
const Preloader = ({ isFirstLoad, currentPageName }: PreloaderType) => {
  const greetingTextRef = useRef<HTMLSpanElement>(null);
  const preloaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let greetingIndex = 0;
    function showGreeting() {
      if (greetingTextRef?.current && greetingIndex < greetings.length) {
        greetingTextRef.current.textContent = greetings[greetingIndex];
      }
      greetingIndex = (greetingIndex + 1);
    }

    function startPreloader() {
      if (!isFirstLoad) return;
      setTimeout(() => {
        setTimeout(() => {
          showGreeting();
          const interval = setInterval(showGreeting, 300);

          setTimeout(() => {
            clearInterval(interval);
            if (preloaderRef?.current) preloaderRef.current.style.display = "none";
          }, isFirstLoad ? 3200 : 300);
        }, 400);
      }, 500);
    }

    startPreloader();
  }, [isFirstLoad]);

  if (isFirstLoad) {
    return (
      <div id="preloader" ref={preloaderRef}>
        <div className="greetings">
          <span ref={greetingTextRef} id="greeting-text"></span>
        </div>
      </div>
    );
  } else {
    return <div>{currentPageName ? currentPageName : '404 Page not found'}</div>;
  }
};

const Loader = ({ setLoaderTimeout }: any) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const loaderRef = useRef(null);
  const pageContainerRef = useRef(null);
  const pageNameRef = useRef(null);
  const location = useLocation();
  const { pathname } = location;
  const currentPageName = pageURLs.find(pageURL => pageURL.path === pathname)?.name;
  const delay = useRef(0);
  const completionTime = useRef(0);
  const scrollTo = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  scrollTo();

  useEffect(() => {

    window.addEventListener('load', scrollTo);
    // This will run only on the first render
    const isVisited = sessionStorage.getItem('isVisited');
    if (isVisited) {
      delay.current = 0.5;
      completionTime.current = 0.8;
      setLoaderTimeout(2000);
      setIsFirstLoad(false);
    } else {
      delay.current = 2.1;
      completionTime.current = 0.8;
      setLoaderTimeout(3700);
      sessionStorage.setItem('isVisited', 'true');
    }
    console.log("isVisited", delay.current);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('load', scrollTo);
    };
  }, [setLoaderTimeout]);

  useEffect(() => {
    scrollTo();
    const tl = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
    tl.to(loaderRef.current, {
      top: 0, height: '100%', duration: completionTime.current, onComplete: () => {
        tl.to(loaderRef.current, { height: '0%', delay: delay.current, duration: completionTime.current });
      }
    });
  }, [pathname]);

  return (<div ref={pageContainerRef} className={styles.loader_container}>
    <div ref={loaderRef} className={styles.loader}>
      <div ref={pageNameRef} className={styles.loader_page_name}>
        <Preloader isFirstLoad={isFirstLoad} currentPageName={currentPageName} />
      </div>
    </div>
  </div>
  );
};

export default Loader;
